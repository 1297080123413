import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const CollapsePickerItem = (props) => {
  const { children, itemClass, onItemClick, tabIndex, ...rest } = props
  return (
    <div
      { ...rest }
      onClick={ onItemClick }
      onKeyPress={ onItemClick }
      role="button"
      className={ cc([itemClass, "collapsepicker-item"]) }
      tabIndex={ tabIndex }>
      { children }
    </div>
  )
}

CollapsePickerItem.propTypes = {
  children: PropTypes.node.isRequired,
  onItemClick: PropTypes.func.isRequired,
  itemClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  tabIndex: PropTypes.number,
}

CollapsePickerItem.defaultProps = {
  itemClass: [],
  tabIndex: 0,
}

export default CollapsePickerItem
