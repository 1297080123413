import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isNilOrEmpty } from 'ramdasauce'

import { getMilestoneLabelWithDate } from '../../utils/MilestoneHelper'

import Icon from '../Icon'

class MilestoneTableItem extends Component {
  render() {
    const { id, plannedOn, completedOn, failedOn, study, program, description, status } = this.props
    const { onEdit, onCancel, canEditAndCancel } = this.props

    const milestoneIsPending = status === 0

    const milestoneIsEditableOrCancelable = milestoneIsPending
    const plannedOnFormatted = !isNilOrEmpty(plannedOn) && moment.utc(plannedOn).format('DD-MMM-YYYY')
    return (
      <tr>
        <td className="id align-right">
          { id }
        </td>
        <td className="date align-right">
          { plannedOnFormatted }
        </td>
        <td className="study align-right">
          { study }
        </td>
        <td className="program align-right">
          { program }
        </td>
        <td className="description">
          { description }
        </td>
        <td className="status align-right">
          { getMilestoneLabelWithDate(status, completedOn, failedOn) }
        </td>
        { canEditAndCancel
          && (
            <td className="button align-right">
              { milestoneIsEditableOrCancelable && this._renderIcon(onEdit, "edit", id) }
            </td>
          ) }
        { canEditAndCancel
          && (
            <td className="button">
              { milestoneIsEditableOrCancelable && this._renderIcon(onCancel, "cancel", id) }
            </td>
          ) }
      </tr>
    )
  }

  _renderIcon = (handleClick, actionType, id) => (
    <div
      className="table-o__clickable-icon"
      onClick={ handleClick }
      id={ `button-${actionType}-milestone-${id}` }
      onKeyPress={ handleClick }
      role="button"
      tabIndex={ 0 }>
      <Icon name={ actionType === "edit" ? "edit" : "circle-cross" } />
    </div>
  )
}

MilestoneTableItem.propTypes = {
  id: PropTypes.number.isRequired,
  study: PropTypes.string.isRequired,
  program: PropTypes.string.isRequired,
  plannedOn: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.number.isRequired,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  canEditAndCancel: PropTypes.bool,
  completedOn: PropTypes.string,
  failedOn: PropTypes.string,
}

MilestoneTableItem.defaultProps = {
  description: "",
  onEdit: () => { },
  onCancel: () => { },
  canEditAndCancel: false,
  completedOn: "",
  failedOn: "",
}

MilestoneTableItem.defaultProps = { canEditAndCancel: false }

export default MilestoneTableItem
