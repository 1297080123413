import React, { Component } from 'react'
import ReactSwitch from 'react-switch'
import PropTypes from 'prop-types'

import BaseModal from '../modals/BaseModal'
import ConfirmFormWithReason from '../modals/ConfirmFormWithReason'
import { TOGGLE_ON_COLOR, TOGGLE_OFF_COLOR } from '../../config/constants'

class SwitchVirtualVisit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedState: props.virtualVisitsEnabled,
      showConfirmationModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { virtualVisitsEnabled } = this.props
    if (prevProps.virtualVisitsEnabled !== virtualVisitsEnabled) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showConfirmationModal: false })
    }
  }

  render() {
    const { checkedState, showConfirmationModal } = this.state
    const { busyTogglingVirtualVisits, toggleVirtualVisitsError, handleConfirmed, handleClose, isStudyArchived } = this.props
    return (
      <div className="switch-virtual-visit">
        { showConfirmationModal && this._renderConfirmationModal(checkedState, busyTogglingVirtualVisits, toggleVirtualVisitsError, handleConfirmed, handleClose) }
        <div className="virtual-visit-label">Virtual Visits</div>
        <ReactSwitch
          checked={ checkedState }
          onChange={ () => this._openModal(checkedState) }
          disabled={ isStudyArchived }
          width={ 40 }
          height={ 20 }
          checkedIcon={ false }
          uncheckedIcon={ false }
          offColor={ TOGGLE_OFF_COLOR }
          onColor={ TOGGLE_ON_COLOR }
        />
      </div>
    )
  }


  _openModal = (checkedState) => {
    this.setState({
      checkedState: !checkedState,
      showConfirmationModal: true,
    })
  }

  _closeModal = (handleClose, checkedState) => {
    this.setState({
      checkedState: !checkedState,
      showConfirmationModal: false,
    })
    handleClose()
  }

  _renderConfirmationModal = (checkedState, busyTogglingVirtualVisits, toggleVirtualVisitsError, handleConfirmed, handleClose) => (
    <BaseModal
      title="Configure Virtual Visits"
      handleClose={ () => this._closeModal(handleClose, checkedState) }
      forceInteraction={ busyTogglingVirtualVisits }
      >
      <ConfirmFormWithReason
        handleCanceled={ () => this._closeModal(handleClose, checkedState) }
        handleConfirmed={ reason => this._confirmVirtualVisitsToggle(handleConfirmed, reason, checkedState) }
        loading={ busyTogglingVirtualVisits }
        error={ toggleVirtualVisitsError }
        label={ `Why do you want to ${checkedState ? 'allow' : 'disable'} Virtual Visits in this country` }
      />
    </BaseModal>
  )

  _confirmVirtualVisitsToggle = (callback, reason, checkedState) => {
    try {
      callback(reason, checkedState)
    } catch (e) {
      console.log(e)
    }
  }
}

SwitchVirtualVisit.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  virtualVisitsEnabled: PropTypes.bool,
  busyTogglingVirtualVisits: PropTypes.bool.isRequired,
  toggleVirtualVisitsError: PropTypes.object,
  isStudyArchived: PropTypes.bool,
}

SwitchVirtualVisit.defaultProps = {
  virtualVisitsEnabled: false,
  isStudyArchived: false,
  toggleVirtualVisitsError: null,
}

export default SwitchVirtualVisit
