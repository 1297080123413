import { all, takeLatest, takeEvery } from 'redux-saga/effects'

import ApiService from '../services/ApiService'

/* ------------- Types ------------- */

import { CountryTypes } from '../redux/CountryRedux'
import { InvestigatorTypes } from '../redux/InvestigatorRedux'
import { MilestoneTypes } from '../redux/MilestoneRedux'
import { StartupTypes } from '../redux/StartupRedux'
import { StudyTypes } from '../redux/StudyRedux'
import { SiteTypes } from '../redux/SiteRedux'
import { SettingsTypes } from '../redux/SettingsRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSaga'
import { fetchCountries, fetchCountriesByStudyId, toggleVirtualVisitsForCountry } from './CountrySaga'
import { fetchSites, addSite, editSite, deleteSite } from './SiteSaga'
import { fetchInvestigators, addInvestigator, deleteInvestigator, updateInvestigator, fetchInvestigatorsByStudy } from './InvestigatorSaga'
import { fetchMilestones, addMilestone, cancelMilestone, updateMilestone, fetchMilestonesByStudy } from './MilestoneSaga'
import {
  fetchStudies, fetchStudy, addStudy, updateStudy, deleteStudy, publishStudy, archiveStudy, uploadStudyConfiguration, downloadStudyConfiguration, updateSchedule,
  enableScreeningPeriod, exportAllPdrs,
} from './StudySaga'
import { uploadGlobalConfiguration, downloadGlobalConfiguration } from './SettingsSaga'

/* ------------- Connect Types To Sagas ------------- */
export default (async () => {
  const apiService = await ApiService
  return function* root() {
    yield all([
      takeLatest(StartupTypes.STARTUP, startup, apiService),

      /* Countries */
      takeLatest(CountryTypes.FETCH_COUNTRIES, fetchCountries, apiService),
      takeLatest(CountryTypes.FETCH_COUNTRIES_BY_STUDY_ID, fetchCountriesByStudyId, apiService),
      takeLatest(CountryTypes.TOGGLE_VIRTUAL_VISITS_FOR_COUNTRY, toggleVirtualVisitsForCountry, apiService),

      /* Sites */
      takeLatest(SiteTypes.FETCH_SITES, fetchSites, apiService),
      takeLatest(SiteTypes.ADD_SITE, addSite, apiService),
      takeLatest(SiteTypes.EDIT_SITE, editSite, apiService),
      takeLatest(SiteTypes.DELETE_SITE, deleteSite, apiService),

      /* Investigators */
      takeLatest(InvestigatorTypes.FETCH_INVESTIGATORS, fetchInvestigators, apiService),
      takeLatest(InvestigatorTypes.FETCH_INVESTIGATORS_BY_STUDY, fetchInvestigatorsByStudy, apiService),
      takeLatest(InvestigatorTypes.ADD_INVESTIGATOR, addInvestigator, apiService),
      takeLatest(InvestigatorTypes.DELETE_INVESTIGATOR, deleteInvestigator, apiService),
      takeLatest(InvestigatorTypes.UPDATE_INVESTIGATOR, updateInvestigator, apiService),

      /* Milestones */
      takeLatest(MilestoneTypes.FETCH_MILESTONES, fetchMilestones, apiService),
      takeLatest(MilestoneTypes.FETCH_MILESTONES_BY_STUDY, fetchMilestonesByStudy, apiService),
      takeLatest(MilestoneTypes.ADD_MILESTONE, addMilestone, apiService),
      takeLatest(MilestoneTypes.CANCEL_MILESTONE, cancelMilestone, apiService),
      takeLatest(MilestoneTypes.UPDATE_MILESTONE, updateMilestone, apiService),

      /* Studies */
      takeLatest(StudyTypes.FETCH_STUDIES, fetchStudies, apiService),
      takeLatest(StudyTypes.FETCH_STUDY, fetchStudy, apiService),
      takeLatest(StudyTypes.ADD_STUDY, addStudy, apiService),
      takeLatest(StudyTypes.UPDATE_STUDY, updateStudy, apiService),
      takeLatest(StudyTypes.DELETE_STUDY, deleteStudy, apiService),
      takeLatest(StudyTypes.UPLOAD_STUDY_CONFIGURATION, uploadStudyConfiguration, apiService),
      takeLatest(StudyTypes.DOWNLOAD_STUDY_CONFIGURATION, downloadStudyConfiguration, apiService),
      takeLatest(StudyTypes.PUBLISH_STUDY, publishStudy, apiService),
      takeLatest(StudyTypes.ARCHIVE_STUDY, archiveStudy, apiService),
      takeLatest(StudyTypes.UPDATE_SCHEDULE, updateSchedule, apiService),
      takeLatest(StudyTypes.ENABLE_SCREENING_PERIOD, enableScreeningPeriod, apiService),
      takeLatest(StudyTypes.EXPORT_ALL_PDRS, exportAllPdrs, apiService),

      /* Settings */
      takeLatest(SettingsTypes.UPLOAD_GLOBAL_CONFIGURATION, uploadGlobalConfiguration, apiService),
      takeLatest(SettingsTypes.DOWNLOAD_GLOBAL_CONFIGURATION, downloadGlobalConfiguration, apiService),
    ])
  }
})()
