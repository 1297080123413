import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isEmpty, both, complement, equals, test } from 'ramda'
import { remove } from 'diacritics'

class MainInput extends Component {
  render() {
    const { inputClass, inputContainerClass, label, onInputChanged, inputType, isDisabled, error, value, returnFullInputEvent, ...rest } = this.props

    return (
      <div className={ ['main-input'].concat(inputContainerClass || []).join(' ') }>
        { this._renderLabel(label) }
        { this._renderInput(inputType, value, onInputChanged, isDisabled, inputClass, returnFullInputEvent, rest) }
        { this._renderError(error) }
      </div>
    )
  }

  _renderLabel = label => (label ? (
    <div className="main-input-title">
      { label }
    </div>
  ) : null)

  _renderInput(inputType, value, onInputChanged, isDisabled, inputClass, returnFullInputEvent, rest) {
    let inputElement = null
    const joinedInputClass = ['main-input-inputfield'].concat(inputClass || []).join(' ')
    switch (inputType) {
      case 'posNumber':
        inputElement = (
          <input
            className={ joinedInputClass }
            type="text"
            value={ value }
            onChange={ this._checkForIllegalCharacters(/[^0-9]/g, onInputChanged, returnFullInputEvent) }
            disabled={ isDisabled }
            onKeyPress={ this._checkForPosNumber }
            maxLength={ 8 }
            pattern="[0-9]"
            { ...rest } />
        )
        break
      case "id":
        inputElement = (
          <input
            className={ joinedInputClass }
            type="text"
            value={ value }
            onChange={ this._checkForIllegalCharacters(/[^a-zA-Z0-9-]/g, onInputChanged, returnFullInputEvent) }
            disabled={ isDisabled }
            onKeyPress={ this._checkForAlphaNumeric }
            pattern="^[a-zA-Z0-9-]*$"
            { ...rest } />
        )
        break
      case "numberWithSign":
        inputElement = (
          <input
            className={ joinedInputClass }
            type="number"
            value={ value }
            onChange={ this._checkInputChanged(onInputChanged, returnFullInputEvent) }
            disabled={ isDisabled }
            onKeyPress={ this._checkForNumberWithSign }
          />
        )
        break
      default:
        inputElement = (
          <input
            className={ joinedInputClass }
            type={ inputType }
            value={ value }
            onChange={ this._checkInputChanged(onInputChanged, returnFullInputEvent) }
            disabled={ isDisabled }
            { ...rest } />
        )
        break
    }
    return inputElement
  }

  _renderError = errorText => (errorText ? (
    <div className="main-input-error">
      { errorText }
    </div>
  ) : null)

  _checkInputChanged = (callback, returnFullInputEvent) => (e) => {
    if (returnFullInputEvent) {
      callback(e)
    } else {
      callback(e.target.value)
    }
  }

  _checkForIllegalCharacters = (regex, callback, returnFullInputEvent) => (e) => {
    this._removeDiacriticsAndSpecialCharacters(e, regex)
    if (returnFullInputEvent) {
      callback(e)
    } else {
      callback(e.target.value)
    }
  }

  _checkForNumberWithSign = (e) => {
    if (this._allowDefaults(e)) {
      return
    }
    const isValidNumberWithSign = test(/[0-9-]/g, e.key)
    if (!isValidNumberWithSign) {
      e.preventDefault()
    }
  }

  _checkForPosNumber = (e) => {
    if (this._allowDefaults(e)) {
      return
    }
    const isValidNumber = both(is(Number), complement(equals(NaN)))
    // Ensure that it is a number and stop the keypress, and prevent diacritics
    if (!isValidNumber(parseInt(e.key, 10))) {
      e.preventDefault()
    }
  }

  _checkForAlphaNumeric = (e) => {
    if (this._allowDefaults(e)) {
      return
    }
    const isValidCharacter = test(/^[a-zA-Z0-9-]*$/, e.key) //= > false
    // Ensure that it is a number, letter or dash or else stop the keypress
    this._removeDiacriticsAndSpecialCharacters(e)
    if (!isValidCharacter) {
      e.preventDefault()
    }
  }

  _allowDefaults = e => ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode)
    // Allow: Ctrl+A, Command+A
    || (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true))
    // Allow: home, end, left, right, down, up
    || (e.keyCode >= 35 && e.keyCode <= 40))
  // let it happen, don't do anything

  _removeDiacriticsAndSpecialCharacters = (e) => {
    e.target.value = is(String, e.target.value) && !isEmpty(e.target.value) ? e.target.value.replace(/[^a-zA-Z0-9-]/g, '') : e.target.value
    e.target.value = remove(e.target.value)
  }
}

MainInput.propTypes = {
  error: PropTypes.string,
  inputType: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'id',
    'image',
    'month',
    'number',
    'posNumber', // custom type
    'numberWithSign', // custom type
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week']),
  isDisabled: PropTypes.bool,
  returnFullInputEvent: PropTypes.bool,
  inputClass: PropTypes.string,
  inputContainerClass: PropTypes.string,
  label: PropTypes.string,
  onInputChanged: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

MainInput.defaultProps = {
  error: null,
  returnFullInputEvent: false,
  isDisabled: false,
  inputType: 'text',
  inputClass: '',
  inputContainerClass: '',
  label: "",
}

export default MainInput
