import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdFileDownload, MdFileUpload, MdAddCircleOutline, MdKeyboardArrowDown, MdSettings, MdLanguage, MdDelete, MdEdit, MdFormatListBulleted, MdAssignment, MdHighlightOff } from 'react-icons/lib/md'
import { FaSignOut, FaWpforms, FaCalendarO, FaGlobe } from 'react-icons/lib/fa'

class Icon extends Component {
  render() {
    const { name, size, color, rotate, style } = this.props

    let renderedIcon = null
    const iconStyle = Object.assign({}, { transform: `rotate(${rotate}deg)` }, style)

    switch (name) {
      case 'download':
        renderedIcon = (
          <MdFileDownload
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'upload':
        renderedIcon = (
          <MdFileUpload
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'logout':
        renderedIcon = (
          <FaSignOut
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'plus':
        renderedIcon = (
          <MdAddCircleOutline
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'calendar':
        renderedIcon = (
          <FaCalendarO
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'chevron-down':
        renderedIcon = (
          <MdKeyboardArrowDown
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'circle-cross':
        renderedIcon = (
          <MdHighlightOff
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'settings':
        renderedIcon = (
          <MdSettings
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'language':
        renderedIcon = (
          <MdLanguage
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'delete':
        renderedIcon = (
          <MdDelete
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'edit':
        renderedIcon = (
          <MdEdit
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'subject':
        renderedIcon = (
          <MdAssignment
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'tasks':
        renderedIcon = (
          <FaWpforms
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'format-list-bulleted':
        renderedIcon = (
          <MdFormatListBulleted
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case 'configure':
        renderedIcon = (
          <FaGlobe
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      default:
        renderedIcon = (
          <div style={ { color: 'red' } }>
            N/A
          </div>
        )
    }
    return renderedIcon
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.number,
  style: PropTypes.object,
}

Icon.defaultProps = {
  size: 24,
  rotate: 0,
  style: {},
  color: 'inherit',
}

export default Icon
