import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { is, isNil, isEmpty } from 'ramda'

import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class AddInvestigatorForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      investigatorOptions: null,
      siteOptions: null,
      selectedInvestigatorId: null,
      selectedSites: null,
      disableConfirm: true,
    }
  }

  componentDidMount() {
    const { investigatorList, siteList, investigatorToUpdate } = this.props
    // Create 'Select'-components options.
    if (isNil(investigatorToUpdate) && is(Array, investigatorList) && !isEmpty(investigatorList)) {
      this._setInvestigatorOptions(investigatorList)
    } else {
      this._setSelectedValues(investigatorToUpdate)
    }
    if (is(Array, siteList) && !isEmpty(siteList)) {
      this._setSiteOptions(siteList)
    }
  }

  componentWillReceiveProps(nextProps) {
    // AVAILABLE INVESTIGATORS
    const { investigatorList, siteList, investigatorToUpdate } = this.props
    const nextInvestigatorList = nextProps.investigatorList
    const receivedNewInvestigatorList = investigatorList !== nextInvestigatorList
    if (receivedNewInvestigatorList && !!investigatorToUpdate && is(Array, nextInvestigatorList) && !isEmpty(nextInvestigatorList)) {
      this._setInvestigatorOptions(nextInvestigatorList)
    }
    // SITES OPTIONS
    const nextSiteList = nextProps.siteList
    const receivedNewSiteList = siteList !== nextSiteList
    if (receivedNewSiteList && is(Array, nextSiteList) && !isEmpty(nextSiteList)) {
      this._setSiteOptions(nextSiteList)
    }
  }

  // eslint-disable-next-line react/sort-comp
  _setSelectedValues(investigator) {
    const selectedInvestigatorId = {
      value: investigator.id,
      label: investigator.displayName,
    }
    const selectedSites = is(Array, investigator.sites) && !isEmpty(investigator.sites) && investigator.sites.map(site => ({
      value: site.id,
      label: site.id,
    }))
    this.setState(() => ({
      selectedInvestigatorId,
      selectedSites,
    }))
  }

  // eslint-disable-next-line react/sort-comp
  _setInvestigatorOptions = (investigators) => {
    const investigatorOptions = investigators.map(investigator => ({
      value: investigator.id,
      label: investigator.displayName,
    }))
    this.setState(() => ({ investigatorOptions }))
  }

  // eslint-disable-next-line react/sort-comp
  _setSiteOptions = (sites) => {
    const siteOptions = sites.map(site => ({
      value: site.id,
      label: site.id,
    }))
    this.setState(() => ({ siteOptions }))
  }

  render() {
    const { investigatorToUpdate, handleConfirmed, handleCanceled, error, loading } = this.props
    const { investigatorOptions, siteOptions, selectedInvestigatorId, selectedSites, disableConfirm } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <Select
          className="portal-select-input"
          options={ investigatorOptions }
          value={ selectedInvestigatorId }
          disabled={ (!!investigatorToUpdate) }
          onChange={ this._selectedInvestigatorChanged }
          placeholder="Select investigator.."
          id="dropdown-investigator" />
        <Select
          className="portal-select-input"
          options={ siteOptions }
          onChange={ this._selectedSitesChanged }
          value={ selectedSites }
          placeholder="Select one or multiple site(s).."
          multi={ true }
          id="dropdown-site" />
        <ConfirmationButtons
          onCancel={ () => this._onCancel(handleCanceled) }
          onConfirm={ () => this._onConfirm(handleConfirmed, selectedInvestigatorId, selectedSites) }
          cancelDisabled={ loading }
          confirmDisabled={ disableConfirm
            || loading
            || isNil(selectedInvestigatorId) || isEmpty(selectedInvestigatorId)
            || isNil(selectedSites) || isEmpty(selectedSites) } />
      </div>
    )
  }


  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )


  _selectedInvestigatorChanged = (selectedInvestigatorOption) => {
    this.setState({
      disableConfirm: false,
      selectedInvestigatorId: selectedInvestigatorOption,
    })
  }

  _selectedSitesChanged = (selectedSites) => {
    this.setState({
      disableConfirm: false,
      selectedSites,
    })
  }

  _onCancel = (cb) => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (cb, investigatorId, selectedSites) => {
    const listOfSites = is(Array, selectedSites) && !isEmpty(selectedSites) && selectedSites.map(site => site.value)
    try {
      cb({
        id: investigatorId.value,
        allSites: false,
        selectedSites: listOfSites,
      })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

AddInvestigatorForm.propTypes = {
  investigatorList: PropTypes.array,
  siteList: PropTypes.array,
  investigatorToUpdate: PropTypes.object,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

AddInvestigatorForm.defaultProps = {
  investigatorList: [],
  siteList: [],
  investigatorToUpdate: null,
  error: null,
}

export default AddInvestigatorForm
