import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const SidebarButton = (props) => {
  const { children, buttonClass, handleClick, id } = props
  const buttonClasses = cc([buttonClass, "sidebar-button"])
  return (
    <div
      className={ buttonClasses }
      onClick={ handleClick }
      id={ id }
      onKeyPress={ handleClick }
      role="button"
      tabIndex={ 0 }>
      { children }
    </div>
  )
}

SidebarButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

SidebarButton.defaultProps = { buttonClass: [] }

export default SidebarButton
