import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isNil } from 'ramda'

import PlanningTableRow from './PlanningTableRow'


class PlanningTableVisit extends Component {
  render() {
    const { title, fastTrackVisit, visits, onPlanningChanged, isDisabled } = this.props
    // render empty <th> tag to maintain the layout
    return (
      <div className="table-o planning">
        <table>
          <thead>
            <tr>
              <th>
                { title }
              </th>
              <th>
                Target
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            { this._renderTableRows(fastTrackVisit, visits, onPlanningChanged, isDisabled) }
          </tbody>
        </table>
      </div>
    )
  }

  _renderTableRows = (fastTrackVisit, visits, onPlanningChanged, isDisabled) => (
    <PlanningTableRow
      title="Fast Track Visit"
      visits={ visits }
      isDisabled={ isDisabled }
      startVisit={ fastTrackVisit }
      onPlanningRowChanged={ this._updateFastTrackVisit(visits, fastTrackVisit, onPlanningChanged) } />
  )

  _updateFastTrackVisit = (visits, originalItem, onPlanningChanged) => (isStartVisitChanged, newVisit) => {
    if (newVisit !== originalItem) {
      const visit = visits.find(visitObject => newVisit === visitObject.reference)
      onPlanningChanged(visit.reference)
    }
  }
}

PlanningTableVisit.propTypes = {
  fastTrackVisit: PropTypes.string,
  onPlanningChanged: PropTypes.func.isRequired,
  title: PropTypes.string,
  visits: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
}

PlanningTableVisit.defaultProps = {
  fastTrackVisit: "",
  title: "",
  isDisabled: false,
}

export default PlanningTableVisit
