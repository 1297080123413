import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import MilestoneActions from '../../redux/MilestoneRedux'

import MilestonesTable from '../../components/tables/MilestoneTable'

class StudyTabMilestones extends Component {
  componentDidMount() {
    // Fetch milestones on opening the screen
    const { study, fetchMilestonesByStudy } = this.props
    if (!isNilOrEmpty(study)) {
      fetchMilestonesByStudy(study.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    // RECEIVED STUDY
    const { study, fetchMilestonesByStudy } = this.props
    const nextStudy = nextProps.study
    if (!equals(study, nextStudy)) {
      fetchMilestonesByStudy(nextStudy.id)
    }
  }

  render() {
    const { study } = this.props
    const { milestonesByStudyList, busyFetchingMilestonesByStudy, fetchMilestonesByStudyError } = this.props

    return (
      <MilestonesTable
        milestones={ milestonesByStudyList }
        specificStudyId={ study.id }
        canAddMilestones={ true }
        canEditAndCancelMilestones
        loadingMilestones={ busyFetchingMilestonesByStudy }
        errorLoadingMilestones={ fetchMilestonesByStudyError } />
    )
  }
}

StudyTabMilestones.propTypes = {
  // study
  study: PropTypes.object,
  // milestones by study
  milestonesByStudyList: PropTypes.array,
  fetchMilestonesByStudy: PropTypes.func.isRequired,
  busyFetchingMilestonesByStudy: PropTypes.bool.isRequired,
  fetchMilestonesByStudyError: PropTypes.object,
}

StudyTabMilestones.defaultProps = {
  // study
  study: null,
  // milestones by study
  milestonesByStudyList: [],
  fetchMilestonesByStudyError: null,
}

const mapStateToProps = state => ({
  // study
  study: state.studies.study,
  // milestones by study
  milestonesByStudyList: state.milestones.milestonesByStudyList,
  busyFetchingMilestonesByStudy: state.milestones.busyFetchingMilestonesByStudy,
  fetchMilestonesByStudyError: state.milestones.fetchMilestonesByStudyError,
})

const mapDispatchToProps = dispatch => ({ fetchMilestonesByStudy: studyId => dispatch(MilestoneActions.fetchMilestonesByStudy(studyId)) })

export default connect(mapStateToProps, mapDispatchToProps)(StudyTabMilestones)
