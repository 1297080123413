import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import cc from 'classcat'

import CollapsePickerItem from '../collapsePicker/CollapsePickerItem'

const StudyLink = (props) => {
  const { study, handleClick, studyClass } = props
  const { id, program } = study
  return (
    <Route
      path={ `/study/${id}` }
      children={ ({ match }) => ( // eslint-disable-line react/no-children-prop
        <CollapsePickerItem
          onItemClick={ handleClick(id) }
          itemClass={ cc([{ selected: match }, studyClass]) }>
          <Link
            to={ `/study/${id}` }
            tabIndex={ -1 }>
            { program && `${program} - ` }
            <strong>
              { id }
            </strong>
          </Link>
        </CollapsePickerItem>
      ) } />
  )
}

StudyLink.propTypes = {
  study: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  studyClass: PropTypes.string,
}

StudyLink.defaultProps = { studyClass: "" }

export default StudyLink
