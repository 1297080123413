import React from 'react'
import PropTypes from 'prop-types'
import { is, isNil, isEmpty } from 'ramda'

import ErrorBox from './ErrorBox'

const ErrorHandler = (props) => {
  const { error, containerClass } = props
  if (is(Array, error)) {
    const nullErrorsFiltered = error.filter((subError => !isNil(subError)))
    // loop over array and provide multiple error boxes
    return !isEmpty(nullErrorsFiltered) && (
      <div className={ containerClass }>
        { nullErrorsFiltered.map((subError, index) => {
          const previousError = nullErrorsFiltered[index - 1]
          const returnErrorbox = previousError
            ? (previousError.title && subError.title ? previousError.title !== subError.title : false) // if the subError.title is empty it means it was stripped because it's under the same main error
            || previousError.message !== subError.message
            || previousError.hint !== subError.hint
            : true
          return returnErrorbox && (
            <ErrorBox
              error={ subError }
              key={ subError.id } />
          )
        }) }
      </div>
    )
  }
  if (!isNil(error)) { // return single ErrorBox
    return <ErrorBox error={ error } />
  }
  return null
}

ErrorHandler.propTypes = {
  containerClass: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

ErrorHandler.defaultProps = {
  containerClass: '',
  error: null,
}

export default ErrorHandler
