import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isNil, isEmpty, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import MainInput from '../inputs/MainInput'
import MainInputGroup from '../inputs/MainInputGroup'
import Dropdown from '../inputs/Dropdown'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class AddSiteToCountryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputSiteIdValue: '',
      isRecurringSite: false,
      inputPhysicalSiteValue: '',
      previousPhysicalSiteValue: '',
      inputPatientRangeFromValue: '',
      inputPatientRangeToValue: '',
      selectedLocation: null,
    }
  }

  componentDidMount() {
    const { availableLocations } = this.props
    this._setLocationOptions(availableLocations)
  }

  componentWillReceiveProps(nextProps) {
    const { availableLocations } = this.props
    const nextAvailableLocations = nextProps.availableLocations
    const receivedNewAvailableLocations = !equals(availableLocations, nextAvailableLocations)
    if (receivedNewAvailableLocations) {
      this._setLocationOptions(nextAvailableLocations)
    }
  }

  // eslint-disable-next-line react/sort-comp
  _setLocationOptions(locations) {
    const locationOptions = is(Array, locations) && !isEmpty(locations) ? locations.map(location => ({
      value: location,
      label: is(String, location) && !isEmpty(location) ? location.replace(/\//g, "\u200B/\u200B").replace(/_/g, "\u00A0") : location,
    })) : []

    const preSelectedLocation = is(Array, locations) && equals(locations.length, 1) ? locationOptions[0] : null

    this.setState({
      locationOptions,
      selectedLocation: preSelectedLocation,
    })
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { inputSiteIdValue, inputPhysicalSiteValue, inputPatientRangeFromValue, inputPatientRangeToValue, isRecurringSite, previousPhysicalSiteValue } = this.state
    const { selectedLocation, locationOptions } = this.state

    const showRangeError = !isNil(inputPatientRangeFromValue) && !isEmpty(inputPatientRangeFromValue) && !isNil(inputPatientRangeToValue) && !isEmpty(inputPatientRangeToValue) && Number(inputPatientRangeFromValue) >= Number(inputPatientRangeToValue)

    return (
      <div>
        { error && this._renderError(error) }
        <MainInput
          value={ inputSiteIdValue }
          label="Enter Site ID"
          inputType="posNumber"
          onInputChanged={ this._siteIdInputChanged(previousPhysicalSiteValue) }
          id="input-site-id" />
        <MainInput
          value={ inputPhysicalSiteValue }
          label="Physical Site"
          maxLength={ 250 }
          isDisabled={ isRecurringSite }
          onInputChanged={ this._physicalSiteInputChanged }
          id="input-site-physical" />
        <HintLabel
          size={ 16 }
          hintClass="main-input-title">
          Time zone
        </HintLabel>
        <Dropdown
          selectedOption={ selectedLocation }
          onSelect={ this._selectLocation }
          options={ locationOptions }
          placeholder="Select time zone" />
        <MainInputGroup label="Enter patient ID range">
          <MainInput
            value={ inputPatientRangeFromValue }
            label="From"
            inputType="posNumber"
            onInputChanged={ this._patientRangeFromChanged }
            id="input-site-patient-range-start" />
          <MainInput
            value={ inputPatientRangeToValue }
            label="To"
            inputType="posNumber"
            onInputChanged={ this._patientRangeToChanged }
            id="input-site-patient-range-end" />
        </MainInputGroup>
        { showRangeError && this._renderError({ message: `To value needs to be higher than ${inputPatientRangeFromValue}` }) }
        <ConfirmationButtons
          onCancel={ () => this._onCancel(handleCanceled) }
          onConfirm={ () => this._onConfirm(handleConfirmed, this.state) }
          cancelDisabled={ loading }
          confirmDisabled={ loading
            || isNilOrEmpty(inputSiteIdValue)
            || showRangeError
            || isNilOrEmpty(inputPhysicalSiteValue)
            || isNilOrEmpty(inputPatientRangeFromValue)
            || isNilOrEmpty(inputPatientRangeToValue)
            || isNilOrEmpty(selectedLocation) } />
      </div>
    )
  }

  _selectLocation = (newLocation) => {
    this.setState({ selectedLocation: newLocation })
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _patientRangeFromChanged = (inputPatientRangeFromValue) => {
    this.setState({ inputPatientRangeFromValue })
  }

  _patientRangeToChanged = (inputPatientRangeToValue) => {
    this.setState({ inputPatientRangeToValue })
  }

  _physicalSiteInputChanged = (inputPhysicalSiteValue) => {
    this.setState({
      inputPhysicalSiteValue: (!inputPhysicalSiteValue.trim()) ? inputPhysicalSiteValue.trim() : inputPhysicalSiteValue,
      previousPhysicalSiteValue: inputPhysicalSiteValue,
    })
  }

  _siteIdInputChanged = previousPhysicalSiteValue => (inputSiteIdValue) => {
    this.setState({
      inputSiteIdValue,
      previousPhysicalSiteValue,
    })
  }

  _onCancel = (cb) => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (cb, site) => {
    try {
      cb({
        id: site.inputSiteIdValue,
        patientStartRange: site.inputPatientRangeFromValue,
        patientEndRange: site.inputPatientRangeToValue,
        description: site.inputPhysicalSiteValue.trim(),
        location: site.selectedLocation.value,
      })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

AddSiteToCountryForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  availableLocations: PropTypes.array.isRequired,
}

AddSiteToCountryForm.defaultProps = { error: null }

export default AddSiteToCountryForm
