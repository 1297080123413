import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { equals, is, isEmpty, propEq, isNil, innerJoin, split, sortWith, prop, forEach } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import MilestoneActions from '../../redux/MilestoneRedux'

import { getMilestoneLabel } from '../../utils/MilestoneHelper'
import { sortByDateProperty, sortInAscendingOrDescending } from '../../utils/SortingHelper'

import HintLabel from '../HintLabel'
import LoadingBox from '../loading/LoadingBox'
import ErrorHandler from '../error/ErrorHandler'

import MainButton from '../buttons/MainButton'
import MainButtonGroup from '../buttons/MainButtonGroup'

import BaseModal from '../modals/BaseModal'
import EditMilestoneForm from '../modals/EditMilestoneForm'
import CancelMilestoneForm from '../modals/CancelMilestoneForm'

import MilestoneTableItem from './MilestoneTableItem'
import MilestoneTableHeaders from './MilestoneTableHeaders'
import MilestoneTableFilterRow from './MilestoneTableFilterRow'

class MilestonesTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterStudyOptions: null,
      filterProgramOptions: null,
      filterStatusOptions: null,
      studyFilter: null,
      programFilter: null,
      statusFilter: null,
      descriptionFilter: '',
      sortMilestonesBy: "id",
      sortAscending: false, // false = descending, true = ascending
      showAddMilestoneModal: false,
      showEditMilestoneModal: false,
      showCancelMilestoneModal: false,
      milestoneToUpdateFromState: null,
      milestoneToCancelFromState: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    // FILTERING
    const { milestones } = this.props
    const nextMilestones = nextProps.milestones
    if (!equals(milestones, nextMilestones) && !isNilOrEmpty(nextMilestones)) {
      this._setFilterOptions(nextMilestones)
    }

    // ADD MILESTONE EXPORT
    const { milestoneToAdd, resetAddMilestoneError } = this.props
    const milestoneHasBeenAdded = milestoneToAdd && !nextProps.milestoneToAdd && !nextProps.addMilestoneError
    if (milestoneHasBeenAdded) {
      this._closeAddMilestoneModal(resetAddMilestoneError)()
    }
    // EDIT MILESTONE EXPORT
    const { updatedMilestone, resetUpdateMilestoneError } = this.props
    const milestoneHasBeenUpdated = updatedMilestone && !nextProps.updatedMilestone && !nextProps.updateMilestoneError
    if (milestoneHasBeenUpdated) {
      this._closeEditMilestoneModal(resetUpdateMilestoneError)()
    }
    // CANCEL MILESTONE EXPORT
    const { idOfMilestoneToCancel, resetCancelMilestoneError } = this.props
    const milestoneHasBeenCanceled = idOfMilestoneToCancel && !nextProps.idOfMilestoneToCancel && !nextProps.cancelMilestoneError
    if (milestoneHasBeenCanceled) {
      this._closeCancelMilestoneModal(resetCancelMilestoneError)()
    }
  }

  render() {
    const { milestones, loadingMilestones, errorLoadingMilestones, specificStudyId, canAddMilestones, canFilterMilestones, canEditAndCancelMilestones } = this.props

    const { busyAddingMilestone, addMilestone, addMilestoneError, resetAddMilestoneError } = this.props
    const { busyUpdatingMilestone, updateMilestone, updateMilestoneError, resetUpdateMilestoneError } = this.props
    const { busyCancelingMilestone, cancelMilestone, cancelMilestoneError, resetCancelMilestoneError } = this.props

    const { milestoneToUpdateFromState, milestoneToCancelFromState } = this.state
    const { showAddMilestoneModal, showEditMilestoneModal, showCancelMilestoneModal } = this.state
    const { sortMilestonesBy, sortAscending, studyFilter, filterStudyOptions, programFilter, filterProgramOptions, statusFilter, filterStatusOptions, descriptionFilter } = this.state

    const hasMilestones = is(Array, milestones) && !isEmpty(milestones)
    return (
      <div>
        { loadingMilestones && this._renderLoadingMilestones() }
        { !loadingMilestones && canAddMilestones && this._renderAddMilestoneButton(busyAddingMilestone) }
        { !isNil(errorLoadingMilestones) && this._renderError(errorLoadingMilestones) }
        { hasMilestones && this._renderTable(canFilterMilestones, canEditAndCancelMilestones, milestones, descriptionFilter, studyFilter, filterStudyOptions, programFilter, filterProgramOptions, statusFilter, filterStatusOptions, sortMilestonesBy, sortAscending) }
        { !hasMilestones && !loadingMilestones && !errorLoadingMilestones && this._renderNoMilestones() }
        { showAddMilestoneModal && !isNilOrEmpty(specificStudyId) && this._renderAddMilestoneModal(addMilestone, busyAddingMilestone, resetAddMilestoneError, addMilestoneError, specificStudyId) }
        { showEditMilestoneModal && this._renderEditMilestoneModal(updateMilestone, busyUpdatingMilestone, resetUpdateMilestoneError, updateMilestoneError, milestoneToUpdateFromState) }
        { showCancelMilestoneModal && this._renderCancelMilestoneModal(cancelMilestone, busyCancelingMilestone, resetCancelMilestoneError, cancelMilestoneError, milestoneToCancelFromState) }
      </div>
    )
  }

  _setFilterOptions = (milestones) => {
    const uniqueIds = []
    const uniquePrograms = []
    const uniqueStatuses = []

    const extractUniqueIdsProgramsAndStatuses = (milestone) => {
      if (isNil(uniqueIds.find(propEq('value', milestone.study.id)))) {
        uniqueIds.push({
          value: milestone.study.id,
          label: milestone.study.id,
        })
      }
      if (isNil(uniquePrograms.find(propEq('value', milestone.study.program)))) {
        uniquePrograms.push({
          value: milestone.study.program,
          label: milestone.study.program,
        })
      }
      if (isNil(uniqueStatuses.find(propEq('value', milestone.state)))) {
        uniqueStatuses.push({
          value: milestone.state,
          label: getMilestoneLabel(milestone.state),
        })
      }
    }

    forEach(extractUniqueIdsProgramsAndStatuses, milestones)

    this.setState(() => ({
      filterStudyOptions: uniqueIds,
      filterProgramOptions: uniquePrograms,
      filterStatusOptions: uniqueStatuses,
    }))
  }

  _renderAddMilestoneButton(busyAddingMilestone) {
    return (
      <MainButtonGroup buttonGroupClass="align-right u-margin--zero">
        <MainButton
          buttonClass="blue u-margin--bottom"
          label="ADD MILESTONE EXPORT"
          icon={ { name: "plus" } }
          handleClick={ this._openAddMilestoneModal }
          isDisabled={ busyAddingMilestone }
          id="button-add-milestone-export" />
      </MainButtonGroup>
    )
  }

  _renderLoadingMilestones = () => <LoadingBox message="Loading milestones" />

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoMilestones = () => (
    <HintLabel>
      No milestones scheduled
    </HintLabel>
  )

  _renderTable(canFilterMilestones, canEditAndCancelMilestones, milestones, descriptionFilter, studyFilter, filterStudyOptions, programFilter, filterProgramOptions, statusFilter, filterStatusOptions, sortMilestonesBy, sortAscending) {
    let filteredMilestones = milestones // map milestones by default to filteredMilestones to be used later on
    let sortedMilestones = []

    // FILTERING
    if (canFilterMilestones) {
      const descriptionFilterArray = split(' ', descriptionFilter.trim())
      filteredMilestones = milestones.filter((milestone) => {
        let milestoneIsAllowed = true
        // filter milestones with nonmatching study ID
        if (!isNilOrEmpty(studyFilter) && !equals(milestone.study.id, studyFilter.value)) {
          milestoneIsAllowed = false
        }
        // filter milestones with nonmatching program name
        if (!isNilOrEmpty(programFilter) && !equals(milestone.study.program, programFilter.value)) {
          milestoneIsAllowed = false
        }
        // filter milestones with nonmatching status
        if (!isNilOrEmpty(statusFilter) && !equals(milestone.state, statusFilter.value)) {
          milestoneIsAllowed = false
        }
        // filter milestones with nonmatching description
        const descriptionMilestoneArray = split(' ', milestone.description)
        const intersected = innerJoin((milestoneDescriptionWord, descriptionFilterWord) => milestoneDescriptionWord.toLowerCase().includes(descriptionFilterWord.toLowerCase()), descriptionMilestoneArray, descriptionFilterArray)
        if (!isNilOrEmpty(descriptionFilter) && (isNilOrEmpty(intersected) || descriptionFilterArray.length > intersected.length)) {
          milestoneIsAllowed = false
        }
        return milestoneIsAllowed
      })
    }

    // SORTING
    switch (sortMilestonesBy) {
      default:
        sortedMilestones = sortWith([sortInAscendingOrDescending(prop(sortMilestonesBy), sortAscending)], filteredMilestones)
        break
      case "plannedOn":
        sortedMilestones = sortWith([sortByDateProperty("plannedOn", sortAscending), sortInAscendingOrDescending(prop("id"), sortAscending)], filteredMilestones)
        break
    }

    return (
      <div className="table-o milestones">
        <table>
          <MilestoneTableHeaders
            canEditAndCancelMilestones={ canEditAndCancelMilestones }
            sortMilestonesBy={ sortMilestonesBy }
            sortAscending={ sortAscending }
            setSorting={ this._setSorting } />
          <tbody>
            { canFilterMilestones && this._renderFilterRow(studyFilter, filterStudyOptions, programFilter, filterProgramOptions, statusFilter, filterStatusOptions, descriptionFilter) }
            { sortedMilestones.map(milestone => (
              <MilestoneTableItem
                key={ milestone.id }
                id={ milestone.id }
                study={ milestone.study.id }
                program={ milestone.study.program }
                plannedOn={ milestone.plannedOn }
                completedOn={ milestone.completedOn }
                failedOn={ milestone.failedOn }
                description={ milestone.description }
                status={ milestone.state }
                canEditAndCancel={ canEditAndCancelMilestones }
                onEdit={ this._openEditMilestoneModal(milestone) }
                onCancel={ this._openCancelMilestoneModal(milestone) } />
            )) }
          </tbody>
        </table>
      </div>
    )
  }

  _renderFilterRow(studyFilter, filterStudyOptions, programFilter, filterProgramOptions, statusFilter, filterStatusOptions, descriptionFilter) {
    return (
      <MilestoneTableFilterRow
        setStudyFilter={ this._setStudyFilter }
        studyFilter={ studyFilter }
        filterStudyOptions={ filterStudyOptions }
        setProgramFilter={ this._setProgramFilter }
        programFilter={ programFilter }
        filterProgramOptions={ filterProgramOptions }
        setStatusFilter={ this._setStatusFilter }
        statusFilter={ statusFilter }
        filterStatusOptions={ filterStatusOptions }
        setDescriptionFilter={ this._setDescriptionFilter }
        descriptionFilter={ descriptionFilter } />
    )
  }

  _setSorting = (previousParameterName, previousSortBy, parameterName) => () => {
    const switchOrdering = equals(previousParameterName, parameterName)
    this.setState({
      sortMilestonesBy: parameterName,
      sortAscending: switchOrdering ? !previousSortBy : previousSortBy,
    })
  }

  _setStudyFilter = (study) => {
    this.setState(() => ({ studyFilter: study }))
  }

  _setProgramFilter = (program) => {
    this.setState(() => ({ programFilter: program }))
  }

  _setStatusFilter = (status) => {
    this.setState(() => ({ statusFilter: status }))
  }

  _setDescriptionFilter = (description) => {
    this.setState(() => ({ descriptionFilter: description }))
  }

  // ADD MILESTONE EXPORT
  _renderAddMilestoneModal(addMilestone, busyAddingMilestone, resetAddMilestoneError, addMilestoneError, studyId) {
    return (
      <BaseModal
        title="ADD MILESTONE EXPORT"
        handleClose={ this._closeAddMilestoneModal(resetAddMilestoneError) }
        forceInteraction={ busyAddingMilestone }>
        <EditMilestoneForm
          handleCanceled={ this._closeAddMilestoneModal(resetAddMilestoneError) }
          handleConfirmed={ this._addMilestoneConfirmed(addMilestone, studyId) }
          error={ addMilestoneError }
          loading={ busyAddingMilestone } />
      </BaseModal>
    )
  }

  _openAddMilestoneModal = () => {
    this.setState({ showAddMilestoneModal: true })
  }

  _closeAddMilestoneModal = resetAddMilestoneError => () => {
    this.setState({ showAddMilestoneModal: false })
    resetAddMilestoneError()
  }

  _addMilestoneConfirmed = (addMilestone, studyId) => (milestoneToAdd) => {
    addMilestone(studyId, milestoneToAdd)
  }

  // EDIT MILESTONE EXPORT
  _renderEditMilestoneModal(updateMilestone, busyUpdatingMilestone, resetUpdateMilestoneError, updateMilestoneError, milestoneToUpdateFromState) {
    return (
      <BaseModal
        title="EDIT MILESTONE EXPORT"
        handleClose={ this._closeEditMilestoneModal(resetUpdateMilestoneError) }
        forceInteraction={ busyUpdatingMilestone }>
        <EditMilestoneForm
          milestone={ milestoneToUpdateFromState }
          handleCanceled={ this._closeEditMilestoneModal(resetUpdateMilestoneError) }
          handleConfirmed={ this._updateMilestoneConfirmed(updateMilestone, milestoneToUpdateFromState.study.id, milestoneToUpdateFromState.id) }
          error={ updateMilestoneError }
          loading={ busyUpdatingMilestone } />
      </BaseModal>
    )
  }

  _openEditMilestoneModal = milestoneToUpdateFromState => () => {
    this.setState({
      showEditMilestoneModal: true,
      milestoneToUpdateFromState,
    })
  }

  _closeEditMilestoneModal = resetUpdateMilestoneError => () => {
    this.setState({
      showEditMilestoneModal: false,
      milestoneToUpdateFromState: null,
    })
    resetUpdateMilestoneError()
  }

  _updateMilestoneConfirmed = (updateMilestone, studyId, milestoneId) => (updatedMilestone) => {
    updateMilestone(studyId, milestoneId, updatedMilestone)
  }

  // CANCEL MILESTONE EXPORT
  _renderCancelMilestoneModal(cancelMilestone, busyCancelingMilestone, resetCancelMilestoneError, cancelMilestoneError, milestoneToCancelFromState) {
    return (
      <BaseModal
        title="CANCEL MILESTONE EXPORT"
        handleClose={ this._closeCancelMilestoneModal(resetCancelMilestoneError) }
        forceInteraction={ busyCancelingMilestone }>
        <CancelMilestoneForm
          milestone={ milestoneToCancelFromState }
          handleCanceled={ this._closeCancelMilestoneModal(resetCancelMilestoneError) }
          handleConfirmed={ this._cancelMilestoneConfirmed(cancelMilestone, milestoneToCancelFromState.study.id, milestoneToCancelFromState.id) }
          error={ cancelMilestoneError }
          loading={ busyCancelingMilestone } />
      </BaseModal>
    )
  }

  _openCancelMilestoneModal = milestoneToCancelFromState => () => {
    this.setState({
      showCancelMilestoneModal: true,
      milestoneToCancelFromState,
    })
  }

  _closeCancelMilestoneModal = resetCancelMilestoneError => () => {
    this.setState({
      showCancelMilestoneModal: false,
      milestoneToCancelFromState: null,
    })
    resetCancelMilestoneError()
  }

  _cancelMilestoneConfirmed = (cancelMilestone, studyId, milestoneId) => () => {
    cancelMilestone(studyId, milestoneId)
  }
}

MilestonesTable.propTypes = {
  milestones: PropTypes.array,
  loadingMilestones: PropTypes.bool.isRequired,
  errorLoadingMilestones: PropTypes.object,
  // allow filtering, editing, canceling, adding, ...
  canFilterMilestones: PropTypes.bool,
  canEditAndCancelMilestones: PropTypes.bool,
  canAddMilestones: PropTypes.bool,
  // global or study specific list
  specificStudyId: PropTypes.string,
  // add milestone
  milestoneToAdd: PropTypes.object,
  addMilestoneError: PropTypes.object,
  busyAddingMilestone: PropTypes.bool.isRequired,
  addMilestone: PropTypes.func.isRequired,
  resetAddMilestoneError: PropTypes.func.isRequired,
  // update milestone
  updatedMilestone: PropTypes.object,
  updateMilestoneError: PropTypes.object,
  busyUpdatingMilestone: PropTypes.bool.isRequired,
  updateMilestone: PropTypes.func.isRequired,
  resetUpdateMilestoneError: PropTypes.func.isRequired,
  // cancel milestone
  idOfMilestoneToCancel: PropTypes.number,
  cancelMilestoneError: PropTypes.object,
  busyCancelingMilestone: PropTypes.bool.isRequired,
  cancelMilestone: PropTypes.func.isRequired,
  resetCancelMilestoneError: PropTypes.func.isRequired,
}

MilestonesTable.defaultProps = {
  milestones: [],
  errorLoadingMilestones: null,
  // allow filtering, editing, canceling, adding, ...
  canFilterMilestones: false,
  canEditAndCancelMilestones: false,
  canAddMilestones: false,
  // global or study specific list
  specificStudyId: "",
  // add milestone
  milestoneToAdd: null,
  addMilestoneError: null,
  // update milestone
  updatedMilestone: null,
  updateMilestoneError: null,
  // cancel milestone
  idOfMilestoneToCancel: null,
  cancelMilestoneError: null,
}

const mapStateToProps = state => ({
  // add milestone
  milestoneToAdd: state.milestones.milestoneToAdd,
  addMilestoneError: state.milestones.addMilestoneError,
  busyAddingMilestone: state.milestones.busyAddingMilestone,
  // update milestone
  updatedMilestone: state.milestones.updatedMilestone,
  updateMilestoneError: state.milestones.updateMilestoneError,
  busyUpdatingMilestone: state.milestones.busyUpdatingMilestone,
  // cancel milestone
  idOfMilestoneToCancel: state.milestones.idOfMilestoneToCancel,
  cancelMilestoneError: state.milestones.cancelMilestoneError,
  busyCancelingMilestone: state.milestones.busyCancelingMilestone,
})

const mapDispatchToProps = dispatch => ({
  fetchMilestonesByStudy: studyId => dispatch(MilestoneActions.fetchMilestonesByStudy(studyId)),
  addMilestone: (studyId, milestoneToAdd) => dispatch(MilestoneActions.addMilestone(studyId, milestoneToAdd)),
  updateMilestone: (studyId, milestoneId, updatedMilestone) => dispatch(MilestoneActions.updateMilestone(studyId, milestoneId, updatedMilestone)),
  cancelMilestone: (studyId, milestoneId) => dispatch(MilestoneActions.cancelMilestone(studyId, milestoneId)),
  resetAddMilestoneError: () => dispatch(MilestoneActions.resetAddMilestoneError()),
  resetUpdateMilestoneError: () => dispatch(MilestoneActions.resetUpdateMilestoneError()),
  resetCancelMilestoneError: () => dispatch(MilestoneActions.resetCancelMilestoneError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MilestonesTable)
