import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
import { is, isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import cc from 'classcat'

import StudyActions from '../../redux/StudyRedux'
import CountryActions from '../../redux/CountryRedux'
import MilestoneActions from '../../redux/MilestoneRedux'

import BaseModal from '../../components/modals/BaseModal'
import EditStudyForm from '../../components/modals/EditStudyForm'

import CollapsePicker from '../../components/collapsePicker/CollapsePicker'
import CollapsePickerItem from '../../components/collapsePicker/CollapsePickerItem'

import Icon from '../../components/Icon'
import StudyLink from '../../components/links/StudyLink'
import SidebarButton from '../../components/buttons/SidebarButton'


export class SidebarContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shownPicker: 0,
      showNewStudyModal: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    // CREATE STUDY
    const { newStudy, resetAddStudyError, history } = this.props
    const newStudyHasBeenAdded = newStudy && !nextProps.newStudy && !nextProps.addStudyError
    if (newStudyHasBeenAdded) {
      this._closeNewStudyModal(resetAddStudyError)()
      history.push(`/study/${newStudy.id}`)
    }
  }

  render() {
    const { children, studies, sidebarCollapsed, history } = this.props
    const { busyAddingStudy, addStudy, addStudyError, resetAddStudyError } = this.props
    const { fetchStudy, fetchCountriesByStudyId, fetchMilestones } = this.props
    const { shownPicker, showNewStudyModal } = this.state

    const hasStudies = !isNilOrEmpty(studies)

    const sidebarClassNames = cc([
      'sidebar-container',
      { "sidebar-container--active": sidebarCollapsed },
    ])

    return (
      <div className={ sidebarClassNames }>
        { /* STUDIES */ }
        { hasStudies && this._renderStudyPicker(shownPicker, studies, fetchStudy, fetchCountriesByStudyId, history) }
        { this._renderCreateStudyButton() }
        { this._renderMilestonesButton(history, fetchMilestones) }
        { this._renderGlobalConfigurationButton(history) }
        { showNewStudyModal && this._renderNewStudyModal(addStudy, resetAddStudyError, addStudyError, busyAddingStudy, studies) }
        { /* PARENT CHILDREN */ }
        { children }
      </div>
    )
  }

  _renderMilestonesButton(history, fetchMilestones) {
    return (
      <SidebarButton
        buttonClass="blue-button"
        handleClick={ this._openReports(history, fetchMilestones) }
        id="button-all-milestones">
        <Icon
          name="tasks"
          color="white" />
        <span>All Milestones</span>
      </SidebarButton>
    )
  }

  _renderGlobalConfigurationButton(history) {
    return (
      <SidebarButton
        buttonClass="blue-button"
        handleClick={ this._openGlobalConfiguration(history) }
        id="button-all-milestones">
        <Icon
          name="configure"
          color="white" />
        <span>Global Configuration</span>
      </SidebarButton>
    )
  }

  _renderCreateStudyButton() {
    return (
      <SidebarButton
        buttonClass="green-button"
        handleClick={ this._openNewStudyModal }
        id="button-create-study">
        <Icon
          name="plus"
          color="white" />
        <span>Create new study</span>
      </SidebarButton>
    )
  }

  _renderStudyPicker(shownPicker, studies, fetchStudy, fetchCountriesByStudyId, history) {
    return (
      <CollapsePicker
        isOpen={ shownPicker === 1 }
        title="STUDIES"
        selecterTitle="Study Overview"
        onSelectTitle="Select a study"
        containerClass="green-picker"
        onSelecterClick={ this._openCertainPicker(shownPicker, 1) }>
        { shownPicker === 1 && this._renderStudyLinks(studies, fetchStudy, fetchCountriesByStudyId, history) }
        { shownPicker !== 1 && this._renderSelectedStudy(studies) }
      </CollapsePicker>
    )
  }

  _renderStudyLinks(studies, fetchStudy, fetchCountriesByStudyId, history) {
    return is(Array, studies) && !isEmpty(studies) && studies.map(study => (
      <StudyLink
        study={ study }
        handleClick={ this._handleStudySelection(fetchStudy, fetchCountriesByStudyId, history) }
        key={ study.id } />
    ))
  }

  _renderSelectedStudy = studies => (
    <Route
      path="/study/:study_id"
      children={ ({ match }) => { // eslint-disable-line react/no-children-prop
        const studyId = match && match.params && match.params.study_id
        const matchingStudy = !isNil(studyId) && is(Array, studies) && !isEmpty(studies) && studies.find(study => study.id === studyId)
        return matchingStudy && !isNilOrEmpty(matchingStudy) ? (
          <CollapsePickerItem
            itemClass="selected collapsepicker-item--preview"
            onItemClick={ () => { } }
            tabIndex={ -1 }>
            <span>
              { `${matchingStudy.program} - ` }
              <strong>
                { matchingStudy.id }
              </strong>
            </span>
          </CollapsePickerItem>) : null
      } } />
  )

  _handleStudySelection = (fetchStudy, fetchCountriesByStudyId, history) => studyId => () => {
    history.push(`/study/${studyId}`) // navigate with history prop manually just in case, because 'enter' keys don't trigger the <Link> container properly
    fetchStudy(studyId)
    fetchCountriesByStudyId(studyId)
    this._closePicker()
  }

  _openSettings = history => () => {
    history.push('/settings')
  }

  _openGlobalConfiguration = history => () => {
    history.push('/globalconfiguration')
  }

  // SIDEBAR PICKER(S)
  _openCertainPicker = (currentPickerShown, pickerToOpen) => () => {
    this.setState(() => ({ shownPicker: currentPickerShown === pickerToOpen ? 0 : pickerToOpen }))
  }

  _closePicker = () => {
    this.setState(() => ({ shownPicker: 0 }))
  }

  // CREATE STUDY
  _renderNewStudyModal(addStudy, resetAddStudyError, addStudyError, busyAddingStudy, studies) {
    return (
      <BaseModal
        title="New Study"
        handleClose={ this._closeNewStudyModal(resetAddStudyError) }
        forceInteraction={ busyAddingStudy }>
        <EditStudyForm
          handleCanceled={ this._closeNewStudyModal(resetAddStudyError) }
          handleConfirmed={ this._newStudyConfirmed(addStudy) }
          loading={ busyAddingStudy }
          error={ addStudyError }
          studies={ studies } />
      </BaseModal>
    )
  }

  _openNewStudyModal = () => {
    this.setState(() => ({ showNewStudyModal: true }))
  }

  _closeNewStudyModal = resetAddStudyError => () => {
    this.setState(() => ({ showNewStudyModal: false }))
    resetAddStudyError()
  }

  _newStudyConfirmed = addStudy => (newStudy) => {
    addStudy(newStudy)
  }

  _openReports = (history, fetchMilestones) => () => {
    fetchMilestones()
    history.push('/milestones')
  }
}

SidebarContainer.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
  sidebarCollapsed: PropTypes.bool.isRequired,
  // milestones
  fetchMilestones: PropTypes.func.isRequired,
  // countries
  fetchCountriesByStudyId: PropTypes.func.isRequired,
  // study
  fetchStudy: PropTypes.func.isRequired,
  // studies
  studies: PropTypes.array,
  // add study
  newStudy: PropTypes.object,
  addStudyError: PropTypes.object,
  addStudy: PropTypes.func.isRequired,
  resetAddStudyError: PropTypes.func.isRequired,
  busyAddingStudy: PropTypes.bool.isRequired,
}

SidebarContainer.defaultProps = {
  children: null,
  // studies
  studies: [],
  // add study
  newStudy: null,
  addStudyError: null,
}

export const mapStateToProps = state => ({
  study: state.studies.study,
  studies: state.studies.studyList,
  newStudy: state.studies.newStudy,
  addStudyError: state.studies.addStudyError,
  busyAddingStudy: state.studies.busyAddingStudy,
})

export const mapDispatchToProps = dispatch => ({
  addStudy: newStudy => dispatch(StudyActions.addStudy(newStudy)),
  fetchStudy: studyId => dispatch(StudyActions.fetchStudy(studyId)),
  resetAddStudyError: () => dispatch(StudyActions.resetAddStudyError()),
  fetchCountriesByStudyId: studyId => dispatch(CountryActions.fetchCountriesByStudyId(studyId)),
  fetchMilestones: () => dispatch(MilestoneActions.fetchMilestones()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer))
