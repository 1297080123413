import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { ascend, prop, sortWith, findIndex, propEq } from 'ramda'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // study
  fetchStudy: ['studyId'],
  fetchStudySuccess: ['study'],
  fetchStudyFailure: ['error'],
  // studies
  fetchStudies: null,
  fetchStudiesSuccess: ['studies'],
  fetchStudiesFailure: ['error'],
  // add
  addStudy: ['newStudy'],
  addStudySuccess: ['study'],
  addStudyFailure: ['error'],
  resetAddStudyError: null,
  // update
  updateStudy: ['previousStudyId', 'studyToUpdate'],
  updateStudySuccess: ['previousStudyId', 'study'],
  updateStudyFailure: ['error'],
  resetUpdateStudyError: null,
  // delete
  deleteStudy: ['studyId'],
  deleteStudySuccess: ['studyId'],
  deleteStudyFailure: ['error'],
  resetDeleteStudyError: null,
  // upload configuration
  uploadStudyConfiguration: ['studyId', 'newStudyConfig'],
  uploadStudyConfigurationSuccess: ['study'],
  uploadStudyConfigurationFailure: ['error'],
  resetUploadStudyConfigurationError: null,
  // download configuration
  downloadStudyConfiguration: ['studyId'],
  downloadStudyConfigurationSuccess: ['study'],
  downloadStudyConfigurationFailure: ['error'],
  resetDownloadStudyConfigurationError: null,
  // publish study
  publishStudy: ['studyId', 'studyStatus'],
  publishStudySuccess: ['study'],
  publishStudyFailure: ['error'],
  resetPublishStudyError: null,
  // archive study
  archiveStudy: ['studyId', 'studyStatus'],
  archiveStudySuccess: ['study'],
  archiveStudyFailure: ['error'],
  resetArchiveStudyError: null,
  // update schedule
  updateSchedule: ['eventsToUpdate', 'associatedEventsToUpdate', 'questionnairesToUpdate', 'associatedQuestionnairesToUpdate', 'fastTrackVisitToUpdate', 'studyWithNewSchedule'],
  updateScheduleSuccess: ['study'],
  updateScheduleFailure: ['error'],
  resetUpdateScheduleError: null,
  // enable screening period
  enableScreeningPeriod: ['studyId'],
  enableScreeningPeriodSuccess: null,
  enableScreeningPeriodFailure: ['error'],
  resetEnableScreeningPeriodError: null,
  // export all pdrs
  exportAllPdrs: ['studyId'],
  exportAllPdrsSuccess: null,
  exportAllPdrsFailure: ['error'],
  resetExportAllPdrsError: null,
})

export const StudyTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // study
  study: null,
  busyFetchingStudy: false,
  fetchStudyError: null,
  // studies
  studyList: [],
  busyFetchingStudies: false,
  fetchStudiesError: null,
  // add
  newStudy: null,
  addStudyError: null,
  busyAddingStudy: false,
  // update
  studyToUpdate: null,
  updateStudyError: null,
  busyUpdatingStudy: false,
  // delete
  studyToDelete: null,
  deleteStudyError: null,
  busyDeletingStudy: false,
  // upload configuration
  newStudyConfig: null,
  uploadStudyConfigurationError: null,
  busyUploadingConfig: false,
  studyToUploadConfig: null,
  // download configuration
  downloadStudyConfigurationError: null,
  busyDownloadingConfig: false,
  studyToDownloadConfig: null,
  // change status
  studyToChangeStatus: null,
  publishStudyError: null,
  archiveStudyError: null,
  busyChangingStatus: false,
  // update schedule
  studyWithNewSchedule: null,
  updateScheduleError: null,
  busyUpdatingSchedule: false,
  // enable screening period
  studyToEnableScreeningPeriod: null,
  enableScreeningPeriodError: null,
  busyEnablingScreeningPeriod: false,
  // export all pdrs
  exportAllPdrsError: null,
  busyExportingAllPdrs: false,
})

/* ------------- Reducers ------------- */
// study
export const fetchStudy = (state, { studyId }) => state.merge({
  busyFetchingStudy: true,
  study: null,
  studyId,
  fetchStudyError: null,
})
export const fetchStudySuccess = (state, { study }) => state.merge({
  busyFetchingStudy: false,
  study,
  studyId: null,
})
export const fetchStudyFailure = (state, { error }) => state.merge({
  busyFetchingStudy: false,
  fetchStudyError: error,
})
// studies
export const fetchStudies = state => state.merge({
  busyFetchingStudies: true,
  studyList: [],
  fetchStudiesError: null,
})
export const fetchStudiesSuccess = (state, { studies }) => state.merge({
  busyFetchingStudies: false,
  studyList: studies,
})
export const fetchStudiesFailure = (state, { error }) => state.merge({
  busyFetchingStudies: false,
  fetchStudiesError: error,
})
// add
export const addStudy = (state, { newStudy }) => state.merge({
  busyAddingStudy: true,
  newStudy,
  addStudyError: null,
})
export const addStudySuccess = (state, { study }) => state.merge({
  busyAddingStudy: false,
  study,
  studyList: sortWith([ascend(prop('program')), ascend(prop('id'))], [...state.studyList, study]),
  newStudy: null,
})
export const addStudyFailure = (state, { error }) => state.merge({
  busyAddingStudy: false,
  addStudyError: error,
})
export const resetAddStudyError = state => state.merge({ addStudyError: null })
// update
export const updateStudy = (state, { studyToUpdate }) => state.merge({
  busyUpdatingStudy: true,
  studyToUpdate,
  updateStudyError: null,
})
export const updateStudySuccess = (state, { previousStudyId, study }) => state.merge({
  busyUpdatingStudy: false,
  study,
  studyList: [
    ...state.studyList.slice(0, findIndex(propEq('id', previousStudyId), state.studyList)), // everything before current study
    {
      ...state.studyList[findIndex(propEq('id', previousStudyId), state.studyList)],
      ...study,
    },
    ...state.studyList.slice(findIndex(propEq('id', previousStudyId), state.studyList) + 1), // everything after current study
  ],
  studyToUpdate: null,
})
export const updateStudyFailure = (state, { error }) => state.merge({
  busyUpdatingStudy: false,
  updateStudyError: error,
})
export const resetUpdateStudyError = state => state.merge({ updateStudyError: null })
// delete
export const deleteStudy = (state, { studyId }) => state.merge({
  busyDeletingStudy: true,
  studyToDelete: studyId,
  deleteStudyError: null,
})
export const deleteStudySuccess = (state, { studyId }) => state.merge({
  busyDeletingStudy: false,
  studyToDelete: null,
  studyList: [
    ...state.studyList.slice(0, findIndex(propEq('id', studyId), state.studyList)), // everything before current study
    ...state.studyList.slice(findIndex(propEq('id', studyId), state.studyList) + 1), // everything after current study
  ],
  study: null,
})
export const deleteStudyFailure = (state, { error }) => state.merge({
  busyDeletingStudy: false,
  deleteStudyError: error,
})
export const resetDeleteStudyError = state => state.merge({ deleteStudyError: null })
// upload configuration
export const uploadStudyConfiguration = (state, { studyId, newStudyConfig }) => state.merge({
  busyUploadingConfig: true,
  newStudyConfig,
  uploadStudyConfigurationError: null,
  studyToUploadConfig: studyId,
})
export const uploadStudyConfigurationSuccess = (state, { study }) => state.merge({
  busyUploadingConfig: false,
  study,
  newStudyConfig: null,
  studyToUploadConfig: null,
})
export const uploadStudyConfigurationFailure = (state, { error }) => state.merge({
  busyUploadingConfig: false,
  uploadStudyConfigurationError: error,
})
export const resetUploadStudyConfigurationError = state => state.merge({ uploadStudyConfigurationError: null })
// download configuration
export const downloadStudyConfiguration = (state, { studyId }) => state.merge({
  busyDownloadingConfig: true,
  downloadStudyConfigurationError: null,
  studyToDownloadConfig: studyId,
})
export const downloadStudyConfigurationSuccess = state => state.merge({
  busyDownloadingConfig: false,
  studyToDownloadConfig: null,
})
export const downloadStudyConfigurationFailure = (state, { error }) => state.merge({
  busyDownloadingConfig: false,
  downloadStudyConfigurationError: error,
})
export const resetDownloadStudyConfigurationError = state => state.merge({ downloadStudyConfigurationError: null })
// publish
export const publishStudy = (state, { studyId }) => state.merge({
  busyChangingStatus: true,
  studyToChangeStatus: studyId,
  publishStudyError: null,
})
export const publishStudySuccess = (state, { study }) => state.merge({
  busyChangingStatus: false,
  study,
  studyToChangeStatus: null,
})
export const publishStudyFailure = (state, { error }) => state.merge({
  busyChangingStatus: false,
  publishStudyError: error,
})
export const resetPublishStudyError = state => state.merge({ publishStudyError: null })
// archive
export const archiveStudy = (state, { studyId }) => state.merge({
  busyChangingStatus: true,
  studyToChangeStatus: studyId,
  archiveStudyError: null,
})
export const archiveStudySuccess = (state, { study }) => state.merge({
  busyChangingStatus: false,
  study,
  studyToChangeStatus: null,
  busyExportingAllPdrs: true,
})
export const archiveStudyFailure = (state, { error }) => state.merge({
  busyChangingStatus: false,
  archiveStudyError: error,
})
export const resetArchiveStudyError = state => state.merge({ archiveStudyError: null })
// update schedule
export const updateSchedule = (state, { studyWithNewSchedule }) => state.merge({
  busyUpdatingSchedule: true,
  studyWithNewSchedule,
  updateScheduleError: null,
})
export const updateScheduleSuccess = (state, { study }) => state.merge({
  busyUpdatingSchedule: false,
  study,
  studyWithNewSchedule: null,
})
export const updateScheduleFailure = (state, { error }) => state.merge({
  busyUpdatingSchedule: false,
  updateScheduleError: error,
})
export const resetUpdateScheduleError = state => state.merge({ updateScheduleError: null })
// enable screening period
export const enableScreeningPeriod = (state, { studyId }) => state.merge({
  busyEnablingScreeningPeriod: true,
  studyToEnableScreeningPeriod: studyId,
  enableScreeningPeriodError: null,
})
export const enableScreeningPeriodSuccess = state => state.merge({
  busyEnablingScreeningPeriod: false,
  studyToEnableScreeningPeriod: null,
  study: {
    ...state.study,
    hasScreening: true,
  },
})
export const enableScreeningPeriodFailure = (state, { error }) => state.merge({
  busyEnablingScreeningPeriod: false,
  enableScreeningPeriodError: error,
})
export const resetEnableScreeningPeriodError = state => state.merge({ enableScreeningPeriodError: null })
// export all pdrs
export const exportAllPdrs = state => state.merge({
  busyExportingAllPdrs: true,
  exportAllPdrsError: null,
})
export const exportAllPdrsSuccess = state => state.merge({ busyExportingAllPdrs: false })
export const exportAllPdrsFailure = (state, { error }) => state.merge({
  busyExportingAllPdrs: false,
  exportAllPdrsError: error,
})
export const resetExportAllPdrsError = state => state.merge({ exportAllPdrsError: null })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // study
  [Types.FETCH_STUDIES]: fetchStudies,
  [Types.FETCH_STUDIES_SUCCESS]: fetchStudiesSuccess,
  [Types.FETCH_STUDIES_FAILURE]: fetchStudiesFailure,
  // studies
  [Types.FETCH_STUDY]: fetchStudy,
  [Types.FETCH_STUDY_SUCCESS]: fetchStudySuccess,
  [Types.FETCH_STUDY_FAILURE]: fetchStudyFailure,
  // add
  [Types.ADD_STUDY]: addStudy,
  [Types.ADD_STUDY_SUCCESS]: addStudySuccess,
  [Types.ADD_STUDY_FAILURE]: addStudyFailure,
  [Types.RESET_ADD_STUDY_ERROR]: resetAddStudyError,
  // update
  [Types.UPDATE_STUDY]: updateStudy,
  [Types.UPDATE_STUDY_SUCCESS]: updateStudySuccess,
  [Types.UPDATE_STUDY_FAILURE]: updateStudyFailure,
  [Types.RESET_UPDATE_STUDY_ERROR]: resetUpdateStudyError,
  // delete
  [Types.DELETE_STUDY]: deleteStudy,
  [Types.DELETE_STUDY_SUCCESS]: deleteStudySuccess,
  [Types.DELETE_STUDY_FAILURE]: deleteStudyFailure,
  [Types.RESET_DELETE_STUDY_ERROR]: resetDeleteStudyError,
  // upload configuration
  [Types.UPLOAD_STUDY_CONFIGURATION]: uploadStudyConfiguration,
  [Types.UPLOAD_STUDY_CONFIGURATION_SUCCESS]: uploadStudyConfigurationSuccess,
  [Types.UPLOAD_STUDY_CONFIGURATION_FAILURE]: uploadStudyConfigurationFailure,
  [Types.RESET_UPLOAD_STUDY_CONFIGURATION_ERROR]: resetUploadStudyConfigurationError,
  // download configuration
  [Types.DOWNLOAD_STUDY_CONFIGURATION]: downloadStudyConfiguration,
  [Types.DOWNLOAD_STUDY_CONFIGURATION_SUCCESS]: downloadStudyConfigurationSuccess,
  [Types.DOWNLOAD_STUDY_CONFIGURATION_FAILURE]: downloadStudyConfigurationFailure,
  [Types.RESET_DOWNLOAD_STUDY_CONFIGURATION_ERROR]: resetDownloadStudyConfigurationError,
  // publish study
  [Types.PUBLISH_STUDY]: publishStudy,
  [Types.PUBLISH_STUDY_SUCCESS]: publishStudySuccess,
  [Types.PUBLISH_STUDY_FAILURE]: publishStudyFailure,
  [Types.RESET_PUBLISH_STUDY_ERROR]: resetPublishStudyError,
  // archive study
  [Types.ARCHIVE_STUDY]: archiveStudy,
  [Types.ARCHIVE_STUDY_SUCCESS]: archiveStudySuccess,
  [Types.ARCHIVE_STUDY_FAILURE]: archiveStudyFailure,
  [Types.RESET_ARCHIVE_STUDY_ERROR]: resetArchiveStudyError,
  // update schedule
  [Types.UPDATE_SCHEDULE]: updateSchedule,
  [Types.UPDATE_SCHEDULE_SUCCESS]: updateScheduleSuccess,
  [Types.UPDATE_SCHEDULE_FAILURE]: updateScheduleFailure,
  [Types.RESET_UPDATE_SCHEDULE_ERROR]: resetUpdateScheduleError,
  // enable screening period
  [Types.ENABLE_SCREENING_PERIOD]: enableScreeningPeriod,
  [Types.ENABLE_SCREENING_PERIOD_SUCCESS]: enableScreeningPeriodSuccess,
  [Types.ENABLE_SCREENING_PERIOD_FAILURE]: enableScreeningPeriodFailure,
  [Types.RESET_ENABLE_SCREENING_PERIOD_ERROR]: resetEnableScreeningPeriodError,
  // export all pdrs
  [Types.EXPORT_ALL_PDRS]: exportAllPdrs,
  [Types.EXPORT_ALL_PDRS_SUCCESS]: exportAllPdrsSuccess,
  [Types.EXPORT_ALL_PDRS_FAILURE]: exportAllPdrsFailure,
  [Types.RESET_EXPORT_ALL_PDRS_ERROR]: resetExportAllPdrsError,
})
