import { call, put, all } from 'redux-saga/effects'
import fileDownload from 'js-file-download'
import { replace, push } from 'react-router-redux'
import { is, isEmpty, equals, isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import moment from 'moment'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

import StudyActions from '../redux/StudyRedux'
import CountryActions from '../redux/CountryRedux'

export function* fetchStudies(api) {
  const { ok, data } = yield call(api.get, queries.Studies())
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.fetchStudiesSuccess(embeddedData))

    // parse url for later use
    const urlParameters = window.location.pathname.split('/')
    const indexOfStudy = urlParameters.indexOf('study')
    const hasStudyInUrl = indexOfStudy !== -1 && urlParameters.length > 1 // obscure check to know if it's even possible a studyId is in the url
    // check for available studies and set single study from array or matching study from url
    const availableStudies = embeddedData
    // if studies are available for the user, go fishing for single study from array or matching study from url
    if (is(Array, availableStudies) && !isEmpty(availableStudies)) {
      // start fishing
      let matchingStudy = null
      // if the url has a matching study in in the url, extract the studyId and check if the study is actually available to the user, and if so fetch the data for that study
      const studyIdFromUrl = urlParameters[indexOfStudy + 1]
      if (hasStudyInUrl) {
        matchingStudy = availableStudies.find(study => study.id === studyIdFromUrl)
      }
      // if there's no matching study in the url but there's only one study available and the user loaded the portal on root
      if (isNilOrEmpty(matchingStudy) && availableStudies.length === 1 && (window.location.pathname === "/" || window.location.pathname === process.env.PUBLIC_URL)) {
        [matchingStudy] = availableStudies
        // if study is not already in url, push it in the browser/navigation stack
        if (!hasStudyInUrl || !equals(matchingStudy.id, studyIdFromUrl)) {
          yield put(push(`/study/${matchingStudy.id}`))
        }
      }
      // if a study matches from url, or only one is available, set that as the selected study
      if (!isNilOrEmpty(matchingStudy)) {
        yield put(StudyActions.fetchStudy(matchingStudy.id))
        yield put(CountryActions.fetchCountriesByStudyId(matchingStudy.id))
        // study from url doesn't match, meaning the user typed in a wrong study or has no access to that study, so redirect the user to root
      } else if (hasStudyInUrl) {
        yield put(replace('/'))
      }
      // if the url has a study "selected" but no studies are available to the user, redirect to root
    } else if (hasStudyInUrl) {
      yield put(replace('/'))
    }
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading studies failed`)
    yield put(StudyActions.fetchStudiesFailure(errorObject))
    // redirect user to root by default, just in case they're on a subpage and they wouldn't view the error
    yield put(push('/'))
  }
}

export function* fetchStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.Study(studyId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.fetchStudySuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading study configuration failed`)
    yield put(StudyActions.fetchStudyFailure(errorObject))
  }
}

export function* addStudy(api, action) {
  const { id, program } = action.newStudy
  const { ok, data } = yield call(api.post, queries.Studies(), {
    id,
    program,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(CountryActions.resetCountriesByStudyId())
    yield put(StudyActions.addStudySuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding study failed`)
    yield put(StudyActions.addStudyFailure(errorObject))
  }
}

export function* updateStudy(api, action) {
  const { previousStudyId, studyToUpdate } = action
  const { id, program } = studyToUpdate
  const { ok, data } = yield call(api.put, queries.Study(previousStudyId), {
    id,
    program,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.updateStudySuccess(previousStudyId, embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Updating study failed`)
    yield put(StudyActions.updateStudyFailure(errorObject))
  }
}

export function* updateSchedule(api, action) {
  const { eventsToUpdate, associatedEventsToUpdate, questionnairesToUpdate, associatedQuestionnairesToUpdate, fastTrackVisitToUpdate, studyWithNewSchedule } = action
  const { id } = studyWithNewSchedule

  const callsToMake = []

  if (!isNilOrEmpty(fastTrackVisitToUpdate)) {
    // enable fast track
    callsToMake.push(call(api.put, queries.FastTrack(id), { fasttrack: fastTrackVisitToUpdate }))
  } else {
    // disable fast track
    callsToMake.push(call(api.put, queries.FastTrack(id), { fasttrack: null }))
  }

  eventsToUpdate.map((event) => {
    const { endVisit, reference, startVisit } = event
    return callsToMake.push(call(api.put, queries.Event(id, reference), {
      startVisit,
      endVisit,
    }))
  })
  associatedEventsToUpdate.map((associatedEvent) => {
    const { associatedVisits, reference } = associatedEvent
    return callsToMake.push(call(api.put, queries.AssociatedEvent(id, reference), { associatedVisits }))
  })
  questionnairesToUpdate.map((questionnaire) => {
    const { endVisit, reference, startVisit } = questionnaire
    return callsToMake.push(call(api.put, queries.Questionnaire(id, reference), {
      startVisit,
      endVisit,
    }))
  })
  associatedQuestionnairesToUpdate.map((associatedQuestionnaire) => {
    const { associatedVisits, reference } = associatedQuestionnaire
    return callsToMake.push(call(api.put, queries.AssociatedQuestionnaire(id, reference), { associatedVisits }))
  })


  const result = yield all(callsToMake)

  const ok = result.every(item => item.ok === true)
  if (ok) {
    yield put(StudyActions.updateScheduleSuccess(studyWithNewSchedule))
  } else {
    const errorObject = result.map((item, index) => addTitleToErrorObject(item.data, index === 0 ? `Updating schedule failed` : ''))
    yield put(StudyActions.updateScheduleFailure(errorObject))
  }
}

export function* deleteStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.delete, queries.Study(studyId))
  if (ok) {
    yield put(StudyActions.deleteStudySuccess(studyId))
  } else {
    const errorObject = addTitleToErrorObject(data, `Deleting study failed`)
    yield put(StudyActions.deleteStudyFailure(errorObject))
  }
}

export function* publishStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.post, queries.PublishStudy(studyId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.publishStudySuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing study status failed`)
    yield put(StudyActions.publishStudyFailure(errorObject))
  }
}

export function* archiveStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.post, queries.ArchiveStudy(studyId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.archiveStudySuccess(embeddedData))
    yield put(StudyActions.exportAllPdrs(studyId))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing study status failed`)
    yield put(StudyActions.archiveStudyFailure(errorObject))
  }
}

export function* uploadStudyConfiguration(api, action) {
  const { studyId, newStudyConfig } = action
  const formData = new FormData()
  formData.append("file", newStudyConfig, newStudyConfig.name)
  const { ok, data } = yield call(api.put, queries.UploadStudy(studyId), formData)
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.uploadStudyConfigurationSuccess(embeddedData))
    yield put(CountryActions.fetchCountriesByStudyId(studyId))
  } else {
    const errorObject = addTitleToErrorObject(data, `Upload study configuration failed`)
    yield put(StudyActions.uploadStudyConfigurationFailure(errorObject))
  }
}

export function* downloadStudyConfiguration(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.DownloadStudy(studyId), {}, {
    headers: { Accept: 'application/zip' },
    responseType: 'blob',
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(StudyActions.downloadStudyConfigurationSuccess())
    fileDownload(embeddedData, `${studyId} configuration.zip`)
  } else {
    const errorObject = addTitleToErrorObject(data, `Download study configuration failed`)
    yield put(StudyActions.downloadStudyConfigurationFailure(errorObject))
  }
}

export function* enableScreeningPeriod(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.put, queries.ActivateScreening(studyId))
  if (ok) {
    yield put(StudyActions.enableScreeningPeriodSuccess())
  } else {
    const errorObject = addTitleToErrorObject(data, `Enabling screening period failed`)
    yield put(StudyActions.enableScreeningPeriodFailure(errorObject))
  }
}

export function* exportAllPdrs(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.ExportAllPdrs(studyId))
  if (ok) {
    if (isNilOrEmpty(data.failedFiles)) {
      yield put(StudyActions.exportAllPdrsSuccess())
    } else {
      const errorObject = addTitleToErrorObject({ message: `The following Patient PDRs were not downloaded: ${data.failedFiles.join(', ')}` }, 'Exporting all PDRs failed')
      yield put(StudyActions.exportAllPdrsFailure(errorObject))
    }
    const byteArray = Uint8Array.from(
      atob(data.exportBase64)
        .split('')
        .map(char => char.charCodeAt(0)),
    )
    fileDownload(new Blob([byteArray], { type: 'application/octet-stream' }), data.fileName)
  } else {
    const errorObject = addTitleToErrorObject(data, 'Exporting all PDRs failed')
    yield put(StudyActions.exportAllPdrsFailure(errorObject))
  }
}
