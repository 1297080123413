import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import dotenv from 'dotenv'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { InteractionType, EventType, PublicClientApplication } from "@azure/msal-browser"

import './assets/themes/project.css'

import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import CreateStore from './redux'

import RootContainer from './containers/RootContainer'

import ApiService from './services/ApiService'

import SessionHandler from './utils/SessionHelper'
import getConfigOrEnvVariable from './utils/ConfigHelper'
import ReactAppInsights from './utils/AppInsightsHelper'

import ApplicationConfig from './config/ApplicationConfig'
import MsalConfig, { additional } from './config/msalConfig'

dotenv.config({ silent: true })

// wait for all the instances/services/configs to be available and the fire up the SPA
Promise.all([MsalConfig, ApplicationConfig, CreateStore]).then((promiseResults) => {
  const msalCfg = promiseResults[0]
  const appCfg = promiseResults[1]
  const reduxStore = promiseResults[2]

  // initialize application insights for the app
  // use this check as it's not implemented in INT environment (displays a console warning because there is no instrumentation key set)
  const instrumentationKey = getConfigOrEnvVariable(appCfg.instrumentationKey, process.env.REACT_APP_INSTRUMENTATION_KEY)
  if (instrumentationKey) {
    ReactAppInsights.init({ instrumentationKey })
  }

  window.sessionHandler = new SessionHandler('admin', msalCfg.endpointApi, process.env.NODE_ENV === 'development')
  // loginRequest.scopes.push(getConfigOrEnvVariable(appCfg.apiApplicationIdUri, process.env.REACT_APP_API_APPLICATIONID_URI))
  const pca = new PublicClientApplication(msalCfg)
  window.msalInstance = pca
  window.sessionHandler.expiry = additional.timeoutInMinutes
  // Handle possible callbacks on id_token or access_token
  // window.msalInstance.handleWindowCallback()
  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      pca.setActiveAccount(event.payload.account)
    }
  })
  ReactDOM.render(
    <MsalProvider instance={ pca }>
      <AuthenticatedTemplate>
        <Provider store={ reduxStore }>
          <RootContainer appConfig={ appCfg } />
        </Provider>
        ,
      </AuthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={ InteractionType.Redirect }
        authenticationRequest={ msalCfg.loginRequest }
      />
    </MsalProvider>,
    document.getElementById('root'),
  )
})
