
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'

import ProfileBadge from './ProfileBadge'

import StudyIdTitle from './StudyIdTitle'
import StudyStatusTitle from './StudyStatusTitle'

import SidebarToggle from '../buttons/SidebarToggle'

export const NavbarHeader = (props) => {
  const { selectedStudy, handleSidebarClick } = props

  return (
    <div className="navbar-header">
      <SidebarToggle handleClick={ handleSidebarClick } />
      <h1 className="navbar-header__title u-margin--zero">
        <span className="navbar-header__name">Study Admin Portal</span>
        <Route
          path="/study/:study_id"
          render={ ({ match }) => <StudyIdTitle id={ match.params.study_id } /> } />
        <Route
          path="/study/:study_id"
          render={ () => <StudyStatusTitle status={ selectedStudy && selectedStudy.workflow } /> } />
      </h1>
      <ProfileBadge />
    </div>
  )
}

NavbarHeader.propTypes = {
  selectedStudy: PropTypes.object,
  handleSidebarClick: PropTypes.func.isRequired,
}

NavbarHeader.defaultProps = { selectedStudy: null }

export const mapStateToProps = state => ({ selectedStudy: state.studies.study })

export default withRouter(connect(mapStateToProps, null)(NavbarHeader))
