import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dropzone from 'react-dropzone'

import SettingsActions from '../../redux/SettingsRedux'

import TabContainer from '../layout/TabContainer'

import Icon from '../../components/Icon'
import HintLabel from '../../components/HintLabel'
import MainButtonGroup from '../../components/buttons/MainButtonGroup'
import MainButton from '../../components/buttons/MainButton'
import BaseModal from '../../components/modals/BaseModal'
import ConfirmForm from '../../components/modals/ConfirmForm'
// import LoadingModal from '../../components/modals/LoadingModal'
import DownloadGlobalConfigurationModal from '../../components/modals/DownloadGlobalConfigurationModal'

class GlobalConfigurationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showUploadGlobalConfigurationModal: false,
      showDownloadGlobalConfigurationModal: false,
      dropzoneIsHovered: false,
      newGlobalConfigFromState: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { busyDownloadingGlobalConfiguration } = this.props
    const { newGlobalConfiguration, resetUploadGlobalConfigurationError, resetDownloadGlobalConfigurationError } = this.props
    // upload global config
    const newGlobalConfigHasBeenUploaded = newGlobalConfiguration && !nextProps.newGlobalConfiguration && !nextProps.uploadGlobalConfigurationError
    if (newGlobalConfigHasBeenUploaded) {
      this._closeUploadConfigModal(resetUploadGlobalConfigurationError)()
    }
    // download
    const configHasBeenDownloaded = busyDownloadingGlobalConfiguration && !nextProps.busyDownloadingGlobalConfiguration && !nextProps.downloadGlobalConfigurationError
    if (configHasBeenDownloaded) {
      this._closeDownloadConfigModal(resetDownloadGlobalConfigurationError)()
    }
  }

  render() {
    const {
      dropzoneIsHovered,
      showUploadGlobalConfigurationModal,
      showDownloadGlobalConfigurationModal,
      newGlobalConfigFromState,
    } = this.state
    // downloads props
    const {
      downloadGlobalConfiguration,
      downloadGlobalConfigurationError,
      busyDownloadingGlobalConfiguration,
      resetDownloadGlobalConfigurationError,
    } = this.props
    // upload props
    const {
      resetUploadGlobalConfigurationError,
      busyUploadingGlobalConfiguration,
      uploadGlobalConfigurationError,
      uploadGlobalConfiguration,
    } = this.props

    return (
      <TabContainer>
        { this._renderGlobalTitle() }
        { this._renderDownloadUploadGlobalButtons(dropzoneIsHovered, downloadGlobalConfiguration) }
        { showUploadGlobalConfigurationModal && this._renderUploadGlobalConfigurationModal(newGlobalConfigFromState, uploadGlobalConfiguration, uploadGlobalConfigurationError, busyUploadingGlobalConfiguration, resetUploadGlobalConfigurationError) }
        { showDownloadGlobalConfigurationModal && this._renderDownloadGlobalConfigurationModal(downloadGlobalConfiguration, downloadGlobalConfigurationError, busyDownloadingGlobalConfiguration, resetDownloadGlobalConfigurationError) }
      </TabContainer>
    )
  }

  _renderGlobalTitle = () => (
    <HintLabel hintClass="u-margin--bottom">
      <strong>Global Configuration</strong>
    </HintLabel>
  )

  _renderDownloadUploadGlobalButtons(dropzoneIsHovered) {
    const icons = {
      downloadIcon: {
        name: 'download',
        size: 25,
      },
      uploadIcon: {
        name: 'upload',
        size: 25,
      },
    }

    let dropzoneClasses = "main-button bordeaux col-6 upload-dropzone"
    if (dropzoneIsHovered) {
      dropzoneClasses += " hover-active"
    }

    return (
      <MainButtonGroup buttonGroupClass="upload-download-buttons">
        <MainButton
          buttonClass="green col-6"
          handleClick={ this._openDownloadConfigurationModal }
          label="Download global configuration"
          icon={ icons.downloadIcon }
          id="button-download-config" />
        <Dropzone
          onDrop={ this._onDrop }
          accept=".zip"
          multiple={ false }
          className={ dropzoneClasses }
          onDragEnter={ this._onDragEnter }
          onDragLeave={ this._onDragLeave }
          id="button-upload-config">
          <Icon
            name="upload"
            size={ 25 } />
          <span>Upload or drop global configuration</span>
        </Dropzone>
      </MainButtonGroup>
    )
  }

  _startDownloadProcedure = downloadGlobalConfiguration => () => {
    this._openDownloadConfigurationModal()
    downloadGlobalConfiguration()
  }

  _onDrop = (files) => {
    this.setState(() => ({ dropzoneIsHovered: false }))
    this._openUploadingGlobalConfigurationModal(files[0])
  }

  _onDragEnter = () => {
    this.setState(() => ({ dropzoneIsHovered: true }))
  }

  _onDragLeave = () => {
    this.setState(() => ({ dropzoneIsHovered: false }))
  }

  // UPLOAD GLOBAL CONFIGURATION MODAL
  _renderUploadGlobalConfigurationModal(newGlobalConfigFromState, uploadGlobalConfiguration, uploadGlobalConfigurationError, busyUploadingGlobalConfiguration, resetUploadGlobalConfigurationError) {
    return (
      <BaseModal
        title="Confirm upload of new global configuration"
        handleClose={ this._closeUploadConfigModal(resetUploadGlobalConfigurationError) }
        forceInteraction={ busyUploadingGlobalConfiguration }>
        <ConfirmForm
          error={ uploadGlobalConfigurationError }
          loading={ busyUploadingGlobalConfiguration }
          handleCanceled={ this._closeUploadConfigModal(resetUploadGlobalConfigurationError) }
          handleConfirmed={ this._uploadConfigConfirmed(newGlobalConfigFromState, uploadGlobalConfiguration) }
          hintText={ `Please confirm a new global configuration (${newGlobalConfigFromState.name}) will be uploaded` }
        />
      </BaseModal>
    )
  }

  _openUploadingGlobalConfigurationModal = (newGlobalConfiguration) => {
    this.setState(() => ({
      showUploadGlobalConfigurationModal: true,
      newGlobalConfigFromState: newGlobalConfiguration,
    }))
  }

  _closeUploadConfigModal = resetUploadGlobalConfigurationError => () => {
    this.setState(() => ({ showUploadGlobalConfigurationModal: false }))
    resetUploadGlobalConfigurationError()
  }

  _uploadConfigConfirmed = (newGlobalConfigFromState, uploadGlobalConfiguration) => () => {
    uploadGlobalConfiguration(newGlobalConfigFromState)
  }

  // DOWNLOAD GLOBAL CONFIGURATION MODAL
  _renderDownloadGlobalConfigurationModal(downloadGlobalConfiguration, downloadGlobalConfigurationError, busyDownloadingGlobalConfiguration, resetDownloadGlobalConfigurationError) {
    return (
      <BaseModal
        title="Download global configuration"
        handleClose={ this._closeDownloadConfigModal(resetDownloadGlobalConfigurationError) }
        forceInteraction={ busyDownloadingGlobalConfiguration }>
        <DownloadGlobalConfigurationModal
          error={ downloadGlobalConfigurationError }
          loading={ busyDownloadingGlobalConfiguration }
          handleCanceled={ this._closeDownloadConfigModal(resetDownloadGlobalConfigurationError) }
          handleConfirmed={ this._startDownloadProcedure(downloadGlobalConfiguration) }
        />
      </BaseModal>
    )
  }

  _openDownloadConfigurationModal = () => {
    this.setState(() => ({ showDownloadGlobalConfigurationModal: true }))
  }

  _closeDownloadConfigModal = resetDownloadGlobalConfigurationError => () => {
    this.setState(() => ({ showDownloadGlobalConfigurationModal: false }))
    resetDownloadGlobalConfigurationError()
  }
}

GlobalConfigurationContainer.propTypes = {
  newGlobalConfiguration: PropTypes.object,
  // upload
  uploadGlobalConfiguration: PropTypes.func.isRequired,
  busyUploadingGlobalConfiguration: PropTypes.bool.isRequired,
  uploadGlobalConfigurationError: PropTypes.object,
  resetUploadGlobalConfigurationError: PropTypes.func.isRequired,
  // download
  downloadGlobalConfiguration: PropTypes.func.isRequired,
  downloadGlobalConfigurationError: PropTypes.object,
  busyDownloadingGlobalConfiguration: PropTypes.bool.isRequired,
  resetDownloadGlobalConfigurationError: PropTypes.func.isRequired,
}

GlobalConfigurationContainer.defaultProps = {
  newGlobalConfiguration: null,
  // upload
  uploadGlobalConfigurationError: null,
  // download
  downloadGlobalConfigurationError: null,
}

const mapStateToProps = state => ({
  newGlobalConfiguration: state.settings.newGlobalConfiguration,
  // upload
  busyUploadingGlobalConfiguration: state.settings.busyUploadingGlobalConfiguration,
  uploadGlobalConfigurationError: state.settings.uploadGlobalConfigurationError,
  // download
  downloadGlobalConfigurationError: state.settings.downloadGlobalConfigurationError,
  busyDownloadingGlobalConfiguration: state.settings.busyDownloadingGlobalConfiguration,
})

const mapDispatchToProps = dispatch => ({
  // upload
  uploadGlobalConfiguration: newGlobalConfiguration => dispatch(SettingsActions.uploadGlobalConfiguration(newGlobalConfiguration)),
  resetUploadGlobalConfigurationError: () => dispatch(SettingsActions.resetUploadGlobalConfigurationError()),
  // download
  downloadGlobalConfiguration: () => dispatch(SettingsActions.downloadGlobalConfiguration()),
  resetDownloadGlobalConfigurationError: () => dispatch(SettingsActions.resetDownloadGlobalConfigurationError()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalConfigurationContainer))
