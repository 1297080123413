import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class ChangeStudyStatusForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: null,
      statusOptions: null,
    }
  }

  componentDidMount() {
    const { status } = this.props
    // Create options for new status
    const optionList = [
      {
        value: 'SET_PUBLISHED',
        label: 'Live',
      },
      {
        value: 'SET_ARCHIVED',
        label: 'DB Lock',
      },
    ]

    // If current status is published, remove the option
    if (status >= 2) {
      optionList.shift()
    }

    // set state
    this.setState(() => ({ statusOptions: optionList }))
  }

  render() {
    const { error, handleCanceled, handleConfirmed, loading } = this.props
    const { selectedOption, statusOptions } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <Select
          className="portal-select-input"
          name="status"
          options={ statusOptions }
          placeholder="Select study status"
          value={ selectedOption }
          onChange={ this._newStatusSelected } />
        <ConfirmationButtons
          onCancel={ () => this._onCancel(handleCanceled) }
          onConfirm={ () => this._onConfirm(handleConfirmed, selectedOption) }
          confirmDisabled={ !selectedOption || loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _newStatusSelected = (status) => {
    // set selected value
    this.setState({ selectedOption: status.value })
  }

  _onCancel = (cb) => {
    try {
      cb()
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (cb, selectedStatus) => {
    try {
      cb(selectedStatus)
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }
}

ChangeStudyStatusForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
}

ChangeStudyStatusForm.defaultProps = { error: null }

export default ChangeStudyStatusForm
