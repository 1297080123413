import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { is, isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import InvestigatorActions from '../../redux/InvestigatorRedux'

import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import CountryCard from '../../components/countryCard/CountryCard'

import BaseModal from '../../components/modals/BaseModal'
import ConfirmForm from '../../components/modals/ConfirmForm'
import AddInvestigatorForm from '../../components/modals/AddInvestigatorForm'
import { isStudyArchived } from '../../utils/StudyHelper'


class StudyTabPermissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddInvestigatorModal: false,
      showDeleteInvestigatorModal: false,
      showUpdateInvestigatorModal: false,
      investigatorToDeleteFromState: null,
      investigatorToUpdateFromState: null,
      countryIdOfInvestigatorToAdd: null,
      countryIdOfInvestigatorToUpdate: null,
      countryIdOfInvestigatorToDelete: null,
      possibleSitesForInvestigator: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    // ADD INVESTIGATOR
    const { newInvestigator, investigatorToDelete, investigatorToUpdate, resetAddInvestigatorError, resetDeleteInvestigatorError, resetUpdateInvestigatorError, fetchInvestigatorsByStudy, study } = this.props
    const newInvestigatorHasBeenAdded = newInvestigator && !nextProps.newInvestigator && !nextProps.addInvestigatorError
    const { id } = study
    if (newInvestigatorHasBeenAdded) {
      this._closeAddInvestigatorModal(resetAddInvestigatorError)()
      fetchInvestigatorsByStudy(id)
    }
    // DELETE INVESTIGATOR
    const investigatorHasBeenDeleted = investigatorToDelete && !nextProps.investigatorToDelete && !nextProps.deleteInvestigatorError
    if (investigatorHasBeenDeleted) {
      this._closeDeleteInvestigatorModal(resetDeleteInvestigatorError)()
      fetchInvestigatorsByStudy(id)
    }
    // UPDATE INVESTIGATOR
    const investigatorHasBeenUpdated = investigatorToUpdate && !nextProps.investigatorToUpdate && !nextProps.updateInvestigatorError
    if (investigatorHasBeenUpdated) {
      this._closeUpdateInvestigatorModal(resetUpdateInvestigatorError)()
      fetchInvestigatorsByStudy(id)
    }
  }

  render() {
    const { showAddInvestigatorModal, showDeleteInvestigatorModal, showUpdateInvestigatorModal } = this.state
    const { investigatorToDeleteFromState, investigatorToUpdateFromState, possibleSitesForInvestigator } = this.state
    const { countryIdOfInvestigatorToAdd, countryIdOfInvestigatorToUpdate, countryIdOfInvestigatorToDelete } = this.state

    const { sites } = this.props
    const { study } = this.props
    const { countries, fetchCountriesByStudyIdError } = this.props
    const { investigatorsByStudy, fetchInvestigatorsByStudyError } = this.props

    const { allInvestigators, fetchInvestigatorsError } = this.props
    const { addInvestigator, addInvestigatorError, busyAddingInvestigator, resetAddInvestigatorError } = this.props
    const { updateInvestigator, updateInvestigatorError, busyUpdatingInvestigator, resetUpdateInvestigatorError } = this.props
    const { deleteInvestigator, deleteInvestigatorError, busyDeletingInvestigator, resetDeleteInvestigatorError } = this.props


    const hasError = !isNil(fetchCountriesByStudyIdError) || !isNil(fetchInvestigatorsByStudyError)

    const hasStudy = !isNilOrEmpty(study)
    const isArchivedStudy = hasStudy && isStudyArchived(study.workflow)
    const hasCountries = is(Array, countries) && !isEmpty(countries)

    return (
      <div>
        { fetchInvestigatorsError && this._renderError(fetchInvestigatorsError) }
        { hasError && this._renderError([fetchCountriesByStudyIdError, fetchInvestigatorsByStudyError]) }
        { !hasError && hasStudy && hasCountries && this._renderCountryCards(countries, investigatorsByStudy, sites, isArchivedStudy) }
        { !hasError && hasStudy && !hasCountries && this._renderNoConfigurationUploaded() }
        { showAddInvestigatorModal && this._renderAddInvestigatorModal(busyAddingInvestigator, study.id, countryIdOfInvestigatorToAdd, allInvestigators, investigatorsByStudy, possibleSitesForInvestigator, addInvestigator, addInvestigatorError, resetAddInvestigatorError, fetchInvestigatorsError) }
        { showUpdateInvestigatorModal && this._renderUpdateInvestigatorModal(busyUpdatingInvestigator, study.id, countryIdOfInvestigatorToUpdate, investigatorToUpdateFromState, possibleSitesForInvestigator, updateInvestigator, updateInvestigatorError, resetUpdateInvestigatorError) }
        { showDeleteInvestigatorModal && this._renderDeleteInvestigatorModal(busyDeletingInvestigator, study.id, countryIdOfInvestigatorToDelete, investigatorToDeleteFromState, deleteInvestigator, deleteInvestigatorError, resetDeleteInvestigatorError) }
      </div>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoConfigurationUploaded = () => (
    <HintLabel>
      No study configuration uploaded.
    </HintLabel>
  )

  _renderCountryCards(countries, investigatorsByStudy, sites, isArchivedStudy) {
    return (
      <div>
        { countries.map(country => (
          <CountryCard
            key={ country.alpha2Code }
            cardType="investigators"
            country={ country }
            isStudyArchived={ isArchivedStudy }
            investigators={ this._filterInvestigatorsByCountry(investigatorsByStudy, country.alpha2Code) }
            handleOpenAddInvestigatorModal={ this._openAddInvestigatorModal(country.alpha2Code, this._filterSitesByCountry(sites, country.alpha2Code)) }
            handleOpenUpdateInvestigatorModal={ this._openUpdateInvestigatorModal(country.alpha2Code, this._filterSitesByCountry(sites, country.alpha2Code)) }
            handleOpenDeleteInvestigatorModal={ this._openDeleteInvestigatorModal(country.alpha2Code) } />
        )) }
      </div>
    )
  }

  // ADD INVESTIGATOR
  _renderAddInvestigatorModal(busyAddingInvestigator, studyId, countryId, investigatorList, investigatorsByStudy, possibleSitesForInvestigator, addInvestigator, addInvestigatorError, resetAddInvestigatorError, fetchInvestigatorsError) {
    // get the already added investigators in that country
    let investigatorsByStudyByCountry = null
    if (is(Array, investigatorsByStudy)) {
      investigatorsByStudyByCountry = investigatorsByStudy.find(listOfInvestigators => listOfInvestigators.country === countryId)
    }
    if (!isNilOrEmpty(investigatorsByStudyByCountry) && is(Array, investigatorsByStudyByCountry.members)) {
      investigatorsByStudyByCountry = investigatorsByStudyByCountry.members
    }
    // filter investigator list with the investigators already added in that country
    let filteredInvestigatorList = investigatorList
    if (is(Array, investigatorList) && is(Array, investigatorsByStudyByCountry)) {
      filteredInvestigatorList = investigatorList.filter(investigator => this._isInvestigatorNotInCountry(investigatorsByStudyByCountry, investigator))
    }
    return (
      <BaseModal
        title="Add Investigator"
        handleClose={ this._closeAddInvestigatorModal(resetAddInvestigatorError) }
        forceInteraction={ busyAddingInvestigator }>
        <AddInvestigatorForm
          investigatorList={ filteredInvestigatorList }
          siteList={ possibleSitesForInvestigator }
          error={ [addInvestigatorError, fetchInvestigatorsError] }
          loading={ busyAddingInvestigator }
          handleCanceled={ this._closeAddInvestigatorModal(resetAddInvestigatorError) }
          handleConfirmed={ this._addInvestigatorConfirmed(studyId, countryId, addInvestigator) } />
      </BaseModal>
    )
  }

  _openAddInvestigatorModal = (countryId, possibleSites) => () => {
    this.setState({
      countryIdOfInvestigatorToAdd: countryId,
      possibleSitesForInvestigator: possibleSites,
      showAddInvestigatorModal: true,
    })
  }

  _closeAddInvestigatorModal = resetAddInvestigatorError => () => {
    this.setState(() => ({
      countryIdOfInvestigatorToAdd: null,
      possibleSitesForInvestigator: null,
      showAddInvestigatorModal: false,
    }))
    resetAddInvestigatorError()
  }

  _addInvestigatorConfirmed = (studyId, countryId, addInvestigator) => (newInvestigator) => {
    addInvestigator(studyId, countryId, newInvestigator)
  }

  _isInvestigatorNotInCountry = (investigatorsByStudyByCountry, investigator) => is(Array, investigatorsByStudyByCountry) && investigatorsByStudyByCountry.find(investigatorInCountry => investigatorInCountry.id === investigator.id) === undefined

  // UPDATE INVESTIGATOR
  _renderUpdateInvestigatorModal(busyUpdatingInvestigator, studyId, countryId, investigatorToUpdate, possibleSitesForInvestigator, updateInvestigator, updateInvestigatorError, resetUpdateInvestigatorError) {
    return (
      <BaseModal
        title="Edit Investigator"
        handleClose={ this._closeUpdateInvestigatorModal(resetUpdateInvestigatorError) }
        forceInteraction={ busyUpdatingInvestigator }>
        <AddInvestigatorForm
          investigatorToUpdate={ investigatorToUpdate }
          siteList={ possibleSitesForInvestigator }
          error={ updateInvestigatorError }
          loading={ busyUpdatingInvestigator }
          handleCanceled={ this._closeUpdateInvestigatorModal(resetUpdateInvestigatorError) }
          handleConfirmed={ this._updateInvestigatorConfirmed(studyId, countryId, updateInvestigator) } />
      </BaseModal>
    )
  }

  _openUpdateInvestigatorModal = (countryId, possibleSites) => investigator => () => {
    this.setState({
      countryIdOfInvestigatorToUpdate: countryId,
      possibleSitesForInvestigator: possibleSites,
      investigatorToUpdateFromState: investigator,
      showUpdateInvestigatorModal: true,
    })
  }

  _closeUpdateInvestigatorModal = resetUpdateInvestigatorError => () => {
    this.setState(() => ({
      countryIdOfInvestigatorToUpdate: null,
      possibleSitesForInvestigator: null,
      investigatorToUpdateFromState: null,
      showUpdateInvestigatorModal: false,
    }))
    resetUpdateInvestigatorError()
  }

  _updateInvestigatorConfirmed = (studyId, countryId, updateInvestigator) => (updatedInvestigator) => {
    updateInvestigator(studyId, countryId, updatedInvestigator)
  }

  // DELETE INVESTIGATOR
  _renderDeleteInvestigatorModal(busyDeletingInvestigator, studyId, countryId, investigatorToDeleteFromState, deleteInvestigator, deleteInvestigatorError, resetDeleteInvestigatorError) {
    const { id, displayName } = investigatorToDeleteFromState
    return (
      <BaseModal
        title="Confirm removal of investigator"
        handleClose={ this._closeDeleteInvestigatorModal(resetDeleteInvestigatorError) }
        forceInteraction={ busyDeletingInvestigator }>
        <ConfirmForm
          error={ deleteInvestigatorError }
          loading={ busyDeletingInvestigator }
          handleCanceled={ this._closeDeleteInvestigatorModal(resetDeleteInvestigatorError) }
          handleConfirmed={ this._deleteInvestigatorConfirmed(studyId, countryId, id, deleteInvestigator) }
          hintText="Please confirm deleting the following investigator: "
          detailedHintText={ `${displayName}` } />
      </BaseModal>
    )
  }

  _openDeleteInvestigatorModal = countryId => investigator => () => {
    this.setState({
      countryIdOfInvestigatorToDelete: countryId,
      investigatorToDeleteFromState: investigator,
      showDeleteInvestigatorModal: true,
    })
  }

  _closeDeleteInvestigatorModal = resetDeleteInvestigatorError => () => {
    this.setState(() => ({
      countryIdOfInvestigatorToDelete: null,
      showDeleteInvestigatorModal: false,
    }))
    resetDeleteInvestigatorError()
  }

  _deleteInvestigatorConfirmed = (studyId, countryId, investigatorId, deleteInvestigator) => () => {
    const InvestigatorToDelete = {
      id: investigatorId,
      allSites: false,
      selectedSites: [], // this deletes the investigator
    }
    deleteInvestigator(studyId, countryId, InvestigatorToDelete)
  }

  _filterSitesByCountry = (sites, countryId) => (is(Array, sites) && sites.filter(site => site.country.toLowerCase() === countryId.toLowerCase())) || []

  _filterInvestigatorsByCountry = (investigators, countryId) => {
    const filteredInvestigators = is(Array, investigators) && investigators.find(investigator => investigator.country.toLowerCase() === countryId.toLowerCase())
    return (filteredInvestigators && filteredInvestigators.members) || []
  }
}

StudyTabPermissions.propTypes = {
  // study
  study: PropTypes.object,
  // countries
  countries: PropTypes.array,
  fetchCountriesByStudyIdError: PropTypes.object,
  // site
  sites: PropTypes.array,
  // investigator by study
  investigatorsByStudy: PropTypes.array,
  fetchInvestigatorsByStudy: PropTypes.func.isRequired,
  fetchInvestigatorsByStudyError: PropTypes.object,
  // all investigators
  allInvestigators: PropTypes.array,
  fetchInvestigatorsError: PropTypes.object,
  // add investigator
  newInvestigator: PropTypes.object,
  addInvestigator: PropTypes.func.isRequired,
  addInvestigatorError: PropTypes.object,
  busyAddingInvestigator: PropTypes.bool.isRequired,
  resetAddInvestigatorError: PropTypes.func.isRequired,
  // update inv
  investigatorToUpdate: PropTypes.object,
  updateInvestigator: PropTypes.func.isRequired,
  updateInvestigatorError: PropTypes.object,
  busyUpdatingInvestigator: PropTypes.bool.isRequired,
  resetUpdateInvestigatorError: PropTypes.func.isRequired,
  // delete inv
  investigatorToDelete: PropTypes.object,
  deleteInvestigator: PropTypes.func.isRequired,
  deleteInvestigatorError: PropTypes.object,
  busyDeletingInvestigator: PropTypes.bool.isRequired,
  resetDeleteInvestigatorError: PropTypes.func.isRequired,
}

StudyTabPermissions.defaultProps = {
  // study
  study: null,
  // countries
  countries: [],
  fetchCountriesByStudyIdError: null,
  // site
  sites: [],
  // investigator by study
  investigatorsByStudy: [],
  fetchInvestigatorsByStudyError: null,
  // all investigators
  allInvestigators: [],
  fetchInvestigatorsError: null,
  // add investigator
  newInvestigator: null,
  addInvestigatorError: null,
  // update inv
  investigatorToUpdate: null,
  updateInvestigatorError: null,
  // delete inv
  investigatorToDelete: null,
  deleteInvestigatorError: null,
}

const mapStateToProps = state => ({
  // study
  study: state.studies.study,
  // countries
  countries: state.countries.countryListByStudyId,
  fetchCountriesByStudyIdError: state.countries.fetchCountriesByStudyIdError,
  // site
  sites: state.sites.siteList,
  // investigator by study
  investigatorsByStudy: state.investigators.investigatorsByStudyList,
  fetchInvestigatorsByStudyError: state.investigators.fetchInvestigatorsByStudyError,
  // all investigators
  allInvestigators: state.investigators.investigatorList,
  fetchInvestigatorsError: state.investigators.fetchInvestigatorsError,
  // add investigator
  newInvestigator: state.investigators.newInvestigator,
  addInvestigatorError: state.investigators.addInvestigatorError,
  busyAddingInvestigator: state.investigators.busyAddingInvestigator,
  // update inv
  investigatorToUpdate: state.investigators.investigatorToUpdate,
  updateInvestigatorError: state.investigators.updateInvestigatorError,
  busyUpdatingInvestigator: state.investigators.busyUpdatingInvestigator,
  // delete inv
  investigatorToDelete: state.investigators.investigatorToDelete,
  deleteInvestigatorError: state.investigators.deleteInvestigatorError,
  busyDeletingInvestigator: state.investigators.busyDeletingInvestigator,
})

const mapDispatchToProps = dispatch => ({
  fetchInvestigatorsByStudy: studyId => dispatch(InvestigatorActions.fetchInvestigatorsByStudy(studyId)),
  addInvestigator: (studyId, countryId, investigatorId, sites) => dispatch(InvestigatorActions.addInvestigator(studyId, countryId, investigatorId, sites)),
  updateInvestigator: (studyId, countryId, updatedInvestigator) => dispatch(InvestigatorActions.updateInvestigator(studyId, countryId, updatedInvestigator)),
  deleteInvestigator: (studyId, countryId, deletedInvestigator) => dispatch(InvestigatorActions.deleteInvestigator(studyId, countryId, deletedInvestigator)),
  resetDeleteInvestigatorError: () => dispatch(InvestigatorActions.resetDeleteInvestigatorError()),
  resetUpdateInvestigatorError: () => dispatch(InvestigatorActions.resetUpdateInvestigatorError()),
  resetAddInvestigatorError: () => dispatch(InvestigatorActions.resetAddInvestigatorError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudyTabPermissions)
