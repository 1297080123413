import React from 'react'
import PropTypes from 'prop-types'

const StudyIdTitle = (props) => {
  const { id } = props
  if (id) {
    return (<span className="navbar-header__id">{ `- ${id}` }</span>)
  }
  return null
}

StudyIdTitle.propTypes = { id: PropTypes.string.isRequired }

export default StudyIdTitle
