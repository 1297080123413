import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter, Route, Link, Switch } from 'react-router-dom'
import { is, isEmpty, isNil } from 'ramda'
import cc from 'classcat'
import shortid from 'shortid'

import StudyActions from '../../redux/StudyRedux'

import TabBar from '../../components/tabbar/TabBar'
import TabBarItem from '../../components/tabbar/TabBarItem'
import TabBarItemGroup from '../../components/tabbar/TabBarItemGroup'
import TabBarDropdown from '../../components/tabbar/TabBarDropdown'
import TabBarDropdownItem from '../../components/tabbar/TabBarDropdownItem'

import BaseModal from '../../components/modals/BaseModal'
import ConfirmForm from '../../components/modals/ConfirmForm'
import EditStudyForm from '../../components/modals/EditStudyForm'
import ChangeStudyStatusForm from '../../components/modals/ChangeStudyStatusForm'
import ErrorHandler from '../../components/error/ErrorHandler'
import LoadingHandler from '../../components/loading/LoadingHandler'

import StudyTabConfig from './StudyTabConfig'
import StudyTabPermissions from './StudyTabPermissions'
import StudyTabPlanning from './StudyTabPlanning'
import StudyTabMilestones from './StudyTabMilestones'

import TabContainer from '../layout/TabContainer'
import SubContainer from '../layout/SubContainer'

const studyRoutes = [
  {
    path: '/study/:study_id',
    exact: true,
    main: () => <StudyTabConfig />,
    label: 'Configuration',
    id: 'button-tab-config',
    to: studyId => `/study/${studyId}`,
    key: shortid.generate(),
  },
  // TODO: when no configuration is available, the permission tab shouldn't be available
  {
    path: '/study/:study_id/permissions',
    exact: true,
    main: () => <StudyTabPermissions />,
    label: 'Permissions',
    id: 'button-tab-permissions',
    to: studyId => `/study/${studyId}/permissions`,
    key: shortid.generate(),
  },
  // TODO: when no configuration is available, the milestone tab shouldn't be available
  {
    path: '/study/:study_id/milestones',
    exact: true,
    main: () => <StudyTabMilestones />,
    label: 'Milestones',
    id: 'button-tab-milestones',
    to: studyId => `/study/${studyId}/milestones`,
    key: shortid.generate(),
  },
  // TODO: when no configuration is available, the study planning tab shouldn't be available
  {
    path: '/study/:study_id/planning',
    exact: true,
    main: () => <StudyTabPlanning />,
    label: 'Study Planning',
    id: 'button-tab-planning',
    to: studyId => `/study/${studyId}/planning`,
    key: shortid.generate(),
  },
]

class StudyContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditStudyModal: false,
      showChangeStudyStatusModal: false,
      showDeleteStudyModal: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { resetDeleteStudyError, resetUpdateStudyError, resetPublishStudyError, resetArchiveStudyError, resetEnableScreeningPeriodError } = this.props
    const { match, history, studyToDelete, studyToUpdate, studyToChangeStatus, studyToEnableScreeningPeriod } = this.props

    const { study_id } = match.params // eslint-disable-line camelcase

    // DELETE STUDY
    const studyHasBeenDeleted = studyToDelete && !nextProps.studyToDelete && !nextProps.deleteStudyError
    if (studyHasBeenDeleted) {
      this._closeDeleteStudyModal(resetDeleteStudyError)()
      history.push('/')
    }

    // UPDATE STUDY
    const studyHasBeenUpdated = studyToUpdate && !nextProps.studyToUpdate && !nextProps.updateStudyError
    if (studyHasBeenUpdated) {
      this._closeEditStudyModal(resetUpdateStudyError)()
      // if the study id changed
      if (studyToUpdate.id !== study_id) { // eslint-disable-line camelcase
        history.replace(`/study/${studyToUpdate.id}`) // navigate the user to the new study id (url is changed and react-router picks it up)
      }
    }

    // PUBLISH AND ARCHIVE STUDY
    const studyStatusHasBeenChanged = studyToChangeStatus && !nextProps.studyToChangeStatus && !nextProps.publishStudyError && !nextProps.archiveStudyError
    if (studyStatusHasBeenChanged) {
      this._closeChangeStudyStatusModal(resetPublishStudyError, resetArchiveStudyError)()
    }

    // ENABLE SCREENING PERIOD
    const screeningPeriodHasBeenEnabled = studyToEnableScreeningPeriod && !nextProps.studyToEnableScreeningPeriod && !nextProps.enableScreeningPeriodError
    if (screeningPeriodHasBeenEnabled) {
      this._closeEnableScreeningPeriodModal(resetEnableScreeningPeriodError)()
    }
  }

  render() {
    const { match, studies } = this.props
    const { deleteStudy, deleteStudyError, resetDeleteStudyError, busyDeletingStudy } = this.props
    const { updateStudy, updateStudyError, resetUpdateStudyError, busyUpdatingStudy } = this.props
    const { publishStudy, publishStudyError, resetPublishStudyError, archiveStudy, resetArchiveStudyError, archiveStudyError, busyChangingStatus } = this.props
    const { enableScreeningPeriod, resetEnableScreeningPeriodError, enableScreeningPeriodError, busyEnablingScreeningPeriod } = this.props
    const { study_id } = match.params // eslint-disable-line camelcase

    const { study, fetchStudyError } = this.props
    const { busyFetchingStudy, busyFetchingCountriesByStudyId, busyFetchingInvestigatorsByStudy, busyFetchingInvestigators, busyFetchingSites } = this.props

    const { showEditStudyModal, showChangeStudyStatusModal, showDeleteStudyModal, showEnableScreeningPeriodModal } = this.state

    const hasError = !isNil(fetchStudyError)
    const hasStudy = !isNil(study) && !isEmpty(study)
    const studyNotArchived = study && study.workflow && study.workflow < 3

    const loadingArray = [
      {
        isLoading: busyFetchingStudy,
        message: "Loading study",
      }, {
        isLoading: busyFetchingCountriesByStudyId,
        message: "Loading countries",
      }, {
        isLoading: busyFetchingSites,
        message: "Loading sites",
      }, {
        isLoading: busyFetchingInvestigatorsByStudy,
        message: "Loading investigators",
      }, {
        isLoading: busyFetchingInvestigators,
        message: "Loading investigators",
      }]

    return (
      <SubContainer>
        <Helmet>
          <title>
            {
              // eslint-disable-next-line camelcase
              study_id
            }
          </title>
        </Helmet>
        { !busyFetchingStudy && !hasError && hasStudy && this._renderTabBar(study_id, study) }
        <TabContainer>
          { !busyFetchingStudy && hasError && this._renderError(fetchStudyError) }
          { this._renderLoadingMessages(loadingArray) }
          { !busyFetchingStudy && !busyFetchingCountriesByStudyId && !busyFetchingInvestigatorsByStudy && !busyFetchingInvestigators && !busyFetchingSites && !hasError && hasStudy && this._renderStudyScreens() }
          { hasStudy && showEditStudyModal && this._renderEditStudyModal(study, studies, updateStudy, resetUpdateStudyError, updateStudyError, busyUpdatingStudy) }
          { hasStudy && showChangeStudyStatusModal && studyNotArchived && this._renderChangeStudyStatusModal(study, publishStudy, resetPublishStudyError, publishStudyError, archiveStudy, resetArchiveStudyError, archiveStudyError, busyChangingStatus) }
          { hasStudy && showDeleteStudyModal && this._renderDeleteStudyModal(study, deleteStudy, resetDeleteStudyError, deleteStudyError, busyDeletingStudy) }
          { hasStudy && showEnableScreeningPeriodModal && this._renderEnableScreeningPeriodModal(study, enableScreeningPeriod, resetEnableScreeningPeriodError, enableScreeningPeriodError, busyEnablingScreeningPeriod) }
        </TabContainer>
      </SubContainer>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />


  _renderLoadingMessages = loadingArray => <LoadingHandler loading={ loadingArray } />

  // TODO: don't render link for permissions tab if study has no configuration uploaded
  _renderStudyScreens = () => (
    <Switch>
      { is(Array, studyRoutes) && !isEmpty(studyRoutes) && studyRoutes.map(studyRoute => (
        <Route
          key={ studyRoute.key }
          path={ studyRoute.path }
          exact={ studyRoute.exact }
          component={ studyRoute.main } />
      )) }
    </Switch>
  )

  // TODO: don't render Route for permissions if study has no configuration uploaded
  _renderTabBar(studyId, study) {
    const isStudyArchived = study.workflow && study.workflow === 3
    const isStudyLive = study.workflow && study.workflow === 2
    const isScreeningPeriodEnabled = !!study.hasScreening
    return (
      <TabBar>
        <TabBarItemGroup>
          { is(Array, studyRoutes) && !isEmpty(studyRoutes) && studyRoutes.map(studyRoute => (
            <Route
              path={ studyRoute.path }
              exact={ studyRoute.exact }
              key={ studyRoute.key }
              children={ ({ match }) => (// eslint-disable-line react/no-children-prop
                <TabBarItem itemClass={ cc([{ "tabbar__item--active": match }]) /* TODO Check class is set with cc() */ }>
                  <Link
                    className="tabbar__item-link"
                    to={ studyRoute.to(studyId) }
                    id={ studyRoute.id }>
                    { studyRoute.label }
                  </Link>
                </TabBarItem>
              ) } />
          )) }
        </TabBarItemGroup>
        <TabBarItemGroup>
          <TabBarDropdown
            title="Settings"
            icon="settings"
            id="button-study-settings">
            <TabBarDropdownItem
              id="button-edit-study"
              disabled={ isStudyArchived }
              title="Edit Study/Program"
              handleClick={ this._openEditStudyModal } />
            { !isStudyArchived && (
              <TabBarDropdownItem
                id="button-change-study-status"
                title="Change Study Status"
                handleClick={ this._openChangeStudyStatusModal } />
            ) }
            { isStudyLive && !isScreeningPeriodEnabled && (
              <TabBarDropdownItem
                id="button-enable-screening-period"
                disabled={ isStudyArchived }
                title="Enable Screening Period"
                handleClick={ this._openEnableScreeningPeriodModal } />
            ) }
            <TabBarDropdownItem
              id="button-delete-study"
              disabled={ isStudyArchived }
              title="Delete Study"
              handleClick={ this._openDeleteStudyModal } />
          </TabBarDropdown>
        </TabBarItemGroup>
      </TabBar>
    )
  }

  // EDIT STUDY
  _renderEditStudyModal(study, studies, updateStudy, resetUpdateStudyError, updateStudyError, busyUpdatingStudy) {
    return (
      <BaseModal
        title="Edit Study"
        handleClose={ this._closeEditStudyModal(resetUpdateStudyError) }
        forceInteraction={ busyUpdatingStudy }>
        <EditStudyForm
          error={ updateStudyError }
          study={ study }
          loading={ busyUpdatingStudy }
          studies={ studies }
          handleCanceled={ this._closeEditStudyModal(resetUpdateStudyError) }
          handleConfirmed={ this._editStudyConfirmed(study, updateStudy) } />
      </BaseModal>
    )
  }

  _openEditStudyModal = () => {
    this.setState(() => ({ showEditStudyModal: true }))
  }

  _closeEditStudyModal = resetUpdateStudyError => () => {
    this.setState(() => ({ showEditStudyModal: false }))
    resetUpdateStudyError()
  }

  _editStudyConfirmed = (originalStudy, updateStudy) => (editedStudy) => {
    const studyToUpdate = Object.assign({}, originalStudy, editedStudy)
    updateStudy(originalStudy.id, studyToUpdate)
  }

  // CHANGE STATUS
  _renderChangeStudyStatusModal(study, publishStudy, resetPublishStudyError, publishStudyError, archiveStudy, resetArchiveStudyError, archiveStudyError, busyChangingStatus) {
    return (
      <BaseModal
        title="Change Study Status"
        handleClose={ this._closeChangeStudyStatusModal(resetPublishStudyError, resetArchiveStudyError) }
        forceInteraction={ busyChangingStatus }>
        <ChangeStudyStatusForm
          error={ publishStudyError || archiveStudyError }
          status={ study.workflow }
          loading={ busyChangingStatus }
          handleCanceled={ this._closeChangeStudyStatusModal(resetPublishStudyError, resetArchiveStudyError) }
          handleConfirmed={ this._changeStudyConfirmed(study.id, publishStudy, archiveStudy) } />
      </BaseModal>
    )
  }

  _openChangeStudyStatusModal = () => {
    this.setState(() => ({ showChangeStudyStatusModal: true }))
  }

  _closeChangeStudyStatusModal = (resetPublishStudyError, resetArchiveStudyError) => () => {
    this.setState(() => ({ showChangeStudyStatusModal: false }))
    resetPublishStudyError()
    resetArchiveStudyError()
  }

  _changeStudyConfirmed = (studyId, publishStudy, archiveStudy) => (newStatus) => {
    switch (newStatus) {
      case 'SET_PUBLISHED':
        publishStudy(studyId)
        break
      case 'SET_ARCHIVED':
        archiveStudy(studyId)
        break
      default:
    }
  }

  // DELETE STUDY
  _renderDeleteStudyModal(studyToDelete, deleteStudy, resetDeleteStudyError, deleteStudyError, busyDeletingStudy) {
    return (
      <BaseModal
        title="Confirm removal of study"
        handleClose={ this._closeDeleteStudyModal(resetDeleteStudyError) }
        forceInteraction={ busyDeletingStudy }>
        <ConfirmForm
          error={ deleteStudyError }
          loading={ busyDeletingStudy }
          handleCanceled={ this._closeDeleteStudyModal(resetDeleteStudyError) }
          handleConfirmed={ this._studyRemovalConfirmed(studyToDelete, deleteStudy) }
          hintText="Please confirm deleting the following study: "
          detailedHintText={ `${studyToDelete.id} - ${studyToDelete.program}` } />
      </BaseModal>
    )
  }

  _openDeleteStudyModal = () => {
    this.setState(() => ({ showDeleteStudyModal: true }))
  }

  _closeDeleteStudyModal = resetDeleteStudyError => () => {
    this.setState(() => ({ showDeleteStudyModal: false }))
    resetDeleteStudyError()
  }

  _studyRemovalConfirmed = (study, deleteStudy) => () => {
    deleteStudy(study.id)
  }

  // ENABLE SCREENING PERIOD STUDY
  _renderEnableScreeningPeriodModal(studyToEnableScreeningPeriod, enableScreeningPeriod, resetEnableScreeningPeriodError, enableScreeningPeriodError, busyEnablingScreeningPeriod) {
    return (
      <BaseModal
        title="Confirm enabling screening period"
        handleClose={ this._closeEnableScreeningPeriodModal(resetEnableScreeningPeriodError) }
        forceInteraction={ busyEnablingScreeningPeriod }>
        <ConfirmForm
          error={ enableScreeningPeriodError }
          loading={ busyEnablingScreeningPeriod }
          handleCanceled={ this._closeEnableScreeningPeriodModal(resetEnableScreeningPeriodError) }
          handleConfirmed={ this._enableScreeningPeriodConfirmed(studyToEnableScreeningPeriod, enableScreeningPeriod) }
          hintText="Please confirm enabling the screening period for the following study: "
          detailedHintText={ `${studyToEnableScreeningPeriod.id} - ${studyToEnableScreeningPeriod.program}` } />
      </BaseModal>
    )
  }

  _openEnableScreeningPeriodModal = () => {
    this.setState(() => ({ showEnableScreeningPeriodModal: true }))
  }

  _closeEnableScreeningPeriodModal = resetEnableScreeningPeriodError => () => {
    this.setState(() => ({ showEnableScreeningPeriodModal: false }))
    resetEnableScreeningPeriodError()
  }

  _enableScreeningPeriodConfirmed = (study, enableScreeningPeriod) => () => {
    enableScreeningPeriod(study.id)
  }
}

StudyContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  // study
  study: PropTypes.object,
  studies: PropTypes.array,
  fetchStudyError: PropTypes.object,
  busyFetchingStudy: PropTypes.bool.isRequired,
  // delete study
  studyToDelete: PropTypes.string,
  deleteStudy: PropTypes.func.isRequired,
  deleteStudyError: PropTypes.object,
  resetDeleteStudyError: PropTypes.func.isRequired,
  busyDeletingStudy: PropTypes.bool.isRequired,
  // update study
  updateStudy: PropTypes.func.isRequired,
  updateStudyError: PropTypes.object,
  studyToUpdate: PropTypes.object,
  busyUpdatingStudy: PropTypes.bool.isRequired,
  resetUpdateStudyError: PropTypes.func.isRequired,
  // publish study
  studyToChangeStatus: PropTypes.string,
  publishStudy: PropTypes.func.isRequired,
  publishStudyError: PropTypes.object,
  resetPublishStudyError: PropTypes.func.isRequired,
  busyChangingStatus: PropTypes.bool.isRequired,
  // archive study
  archiveStudy: PropTypes.func.isRequired,
  resetArchiveStudyError: PropTypes.func.isRequired,
  archiveStudyError: PropTypes.object,
  // loading booleans
  busyFetchingCountriesByStudyId: PropTypes.bool.isRequired,
  busyFetchingInvestigatorsByStudy: PropTypes.bool.isRequired,
  busyFetchingInvestigators: PropTypes.bool.isRequired,
  busyFetchingSites: PropTypes.bool.isRequired,
  // enable screening period
  studyToEnableScreeningPeriod: PropTypes.string,
  enableScreeningPeriod: PropTypes.func.isRequired,
  enableScreeningPeriodError: PropTypes.object,
  resetEnableScreeningPeriodError: PropTypes.func.isRequired,
  busyEnablingScreeningPeriod: PropTypes.bool.isRequired,
}

StudyContainer.defaultProps = {
  // study
  study: null,
  studies: [],
  fetchStudyError: null,
  // delete study
  studyToDelete: "",
  deleteStudyError: null,
  // update study
  updateStudyError: null,
  studyToUpdate: null,
  // publish study
  studyToChangeStatus: "",
  publishStudyError: null,
  // archive study
  archiveStudyError: null,
  // loading booleans
  // enable screening period
  studyToEnableScreeningPeriod: "",
  enableScreeningPeriodError: null,
}

const mapStateToProps = state => ({
  // study
  study: state.studies.study,
  fetchStudyError: state.studies.fetchStudyError,
  busyFetchingStudy: state.studies.busyFetchingStudy,
  studies: state.studies.studyList,
  // countries
  busyFetchingCountriesByStudyId: state.countries.busyFetchingCountriesByStudyId,
  // investigator by study
  busyFetchingInvestigatorsByStudy: state.investigators.busyFetchingInvestigatorsByStudy,
  // all investigators
  busyFetchingInvestigators: state.investigators.busyFetchingInvestigators,
  // sites
  busyFetchingSites: state.sites.busyFetchingSites,
  // change status
  studyToChangeStatus: state.studies.studyToChangeStatus,
  publishStudy: state.studies.publishStudy,
  archiveStudy: state.studies.archiveStudy,
  publishStudyError: state.studies.publishStudyError,
  archiveStudyError: state.studies.archiveStudyError,
  busyChangingStatus: state.studies.busyChangingStatus,
  // delete study
  studyToDelete: state.studies.studyToDelete,
  deleteStudyError: state.studies.deleteStudyError,
  busyDeletingStudy: state.studies.busyDeletingStudy,
  // update study
  studyToUpdate: state.studies.studyToUpdate,
  updateStudyError: state.studies.updateStudyError,
  busyUpdatingStudy: state.studies.busyUpdatingStudy,
  // enable screening period
  studyToEnableScreeningPeriod: state.studies.studyToEnableScreeningPeriod,
  enableScreeningPeriodError: state.studies.enableScreeningPeriodError,
  busyEnablingScreeningPeriod: state.studies.busyEnablingScreeningPeriod,
})

const mapDispatchToProps = dispatch => ({
  publishStudy: studyId => dispatch(StudyActions.publishStudy(studyId)),
  archiveStudy: studyId => dispatch(StudyActions.archiveStudy(studyId)),
  updateStudy: (previousStudyId, studyToUpdate) => dispatch(StudyActions.updateStudy(previousStudyId, studyToUpdate)),
  deleteStudy: studyId => dispatch(StudyActions.deleteStudy(studyId)),
  enableScreeningPeriod: studyId => dispatch(StudyActions.enableScreeningPeriod(studyId)),
  resetDeleteStudyError: () => dispatch(StudyActions.resetDeleteStudyError()),
  resetUpdateStudyError: () => dispatch(StudyActions.resetUpdateStudyError()),
  resetPublishStudyError: () => dispatch(StudyActions.resetPublishStudyError()),
  resetArchiveStudyError: () => dispatch(StudyActions.resetArchiveStudyError()),
  resetEnableScreeningPeriodError: () => dispatch(StudyActions.resetEnableScreeningPeriodError()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyContainer))
