import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import cc from 'classcat'

import Icon from '../Icon'

class MainButton extends Component {
  render() {
    const { label, icon, buttonClass, handleClick, isDisabled, id } = this.props
    return (
      <button
        type="button"
        className={ cc([buttonClass, "main-button"]) }
        onClick={ handleClick }
        disabled={ isDisabled }
        id={ id }>
        { (isNil(Icon) || !icon) ? null : this._renderIcon(icon) }
        <span>
          { label }
        </span>
      </button>
    )
  }

  _renderIcon = icon => (
    <Icon
      name={ icon.name }
      size={ icon.size }
      color={ icon.color } />
  )
}

MainButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
  }),
  buttonClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

MainButton.defaultProps = {
  label: '',
  icon: null,
  isDisabled: false,
  buttonClass: [],
}

export default MainButton
