import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isEmpty } from 'ramda'

import PlanningTableRow from './PlanningTableRow'
import PlanningTableRowAssociatedToVisit from './PlanningTableRowAssociatedToVisit'

class PlanningTable extends Component {
  render() {
    const { title, list, visits, onPlanningChanged, isDisabled, associatedToVisits } = this.props
    return (
      <div className="table-o planning">
        <table>
          <thead>
            <tr>
              <th>
                { title }
              </th>
              <th>
                { associatedToVisits ? 'Visit' : 'Start' }
              </th>
              <th>
                { associatedToVisits ? 'Offset' : 'End' }
              </th>
            </tr>
          </thead>
          <tbody>
            { !associatedToVisits && this._renderTableRows(list, visits, onPlanningChanged, isDisabled) }
            {/* // TODO only visible for archived studies */}
            { associatedToVisits && this._renderAssociatedTableRows(list, visits, onPlanningChanged, isDisabled) }
          </tbody>
        </table>
      </div>
    )
  }

  _renderTableRows(list, visits, onPlanningChanged, isDisabled) {
    return is(Array, list) && list.map(item => (
      <PlanningTableRow
        key={ item.reference }
        title={ item.value }
        startVisit={ item.startVisit }
        endVisit={ item.endVisit }
        visits={ visits }
        isDisabled={ isDisabled }
        onPlanningRowChanged={ this._updateList(list, item, onPlanningChanged) } />
    ))
  }

  _renderAssociatedTableRows(list, visits, onPlanningChanged, isDisabled) {
    return is(Array, list) && (list.map(item => (
      <PlanningTableRowAssociatedToVisit
        key={ item.reference }
        reference={ item.reference }
        title={ item.value }
        associatedVisits={ item.associatedVisits }
        visits={ visits }
        isDisabled={ isDisabled }
        onPlanningRowChanged={ this._updateAssociatedList(onPlanningChanged) } />
    )))
  }

  _updateList = (originalList, originalItem, onPlanningChanged) => (isStartVisitChanged, newVisit) => {
    const visitToUpdate = isStartVisitChanged ? { startVisit: newVisit } : { endVisit: newVisit }
    const updatedList = originalList.map(listItem => (listItem.reference === originalItem.reference
      ? {
        ...listItem,
        ...visitToUpdate,
      } : listItem))
    onPlanningChanged(updatedList)
  }

  _updateAssociatedList = onPlanningChanged => (associatedVisits, reference, value) => {
    onPlanningChanged({ associatedVisits: [...associatedVisits], reference, value })
  }
}

PlanningTable.propTypes = {
  list: PropTypes.array.isRequired,
  onPlanningChanged: PropTypes.func.isRequired,
  title: PropTypes.string,
  visits: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  associatedToVisits: PropTypes.bool,
}

PlanningTable.defaultProps = {
  title: "",
  isDisabled: false,
  associatedToVisits: false,
}

export default PlanningTable
