import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { is, isEmpty, isNil, equals, differenceWith } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import StudyActions from '../../redux/StudyRedux'

import { isStudyArchived } from '../../utils/StudyHelper'

import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import PlanningTable from '../../components/tables/PlanningTable'
import ConfirmationButtons from '../../components/buttons/ConfirmationButtons'
import MainButton from '../../components/buttons/MainButton'
import MainButtonGroup from '../../components/buttons/MainButtonGroup'
import PlanningTableVisit from '../../components/tables/PlanningTableVisit'


export class StudyTabPlanning extends Component {
  constructor(props) {
    super(props)

    const { study } = this.props

    // fast track state is needed for direct changes and disabling of the buttons
    this.state = {
      studyWithNewSchedule: study,
      showFastTrackPlanning: !!study.fastTrackVisit,
      originalFastTrackVisit: study.fastTrackVisit,
    }
  }

  componentDidMount() {
    const { resetUpdateScheduleError } = this.props
    // todo: reset updatePlanningError
    resetUpdateScheduleError()
  }

  componentDidUpdate(prevProps, prevState) {
    const { studyWithNewSchedule, showFastTrackPlanning } = this.state
    const { updateSchedule } = this.props
    // disable fast track
    if (!showFastTrackPlanning && prevState.showFastTrackPlanning && isNilOrEmpty(studyWithNewSchedule.fastTrackVisit)) {
      updateSchedule([], [], [], [], null, studyWithNewSchedule)
    }
  }

  render() {
    const { study } = this.props
    const { visits } = study
    const { busyUpdatingSchedule, updateSchedule, updateScheduleError } = this.props
    const { studyWithNewSchedule, showFastTrackPlanning, originalFastTrackVisit } = this.state
    const { events, questionnaires, associatedEvents, associatedQuestionnaires } = studyWithNewSchedule
    const { fastTrackVisit } = studyWithNewSchedule


    const hasError = !isNil(updateScheduleError)
    const hasEvents = is(Array, events) && !isEmpty(events)
    const hasQuestionnaires = is(Array, questionnaires) && !isEmpty(questionnaires)
    const hasAssociatedEvents = is(Array, associatedEvents) && !isEmpty(associatedEvents)
    const hasAssociatedQuestionnaires = is(Array, associatedQuestionnaires) && !isEmpty(associatedQuestionnaires)
    const studyIsArchived = isStudyArchived(study.workflow)
    return (
      <div>
        { hasError && this._renderError(updateScheduleError) }
        { this._renderFastTrackButton(showFastTrackPlanning, originalFastTrackVisit, studyIsArchived) }
        { hasEvents && (
          <PlanningTable
            title="Recurrent Events"
            list={ events }
            visits={ visits }
            isDisabled={ studyIsArchived }
            onPlanningChanged={ this._changePlanning(studyWithNewSchedule, "events") } />
        ) }
        { hasQuestionnaires && (
          <PlanningTable
            title="Recurrent PRO/Questionnaires"
            list={ questionnaires }
            visits={ visits }
            isDisabled={ studyIsArchived }
            onPlanningChanged={ this._changePlanning(studyWithNewSchedule, "questionnaires") } />
        ) }
        { hasAssociatedEvents && (
        <PlanningTable
          title="Associated Events"
          associatedToVisits={ true }
          list={ associatedEvents }
          visits={ visits }
          isDisabled={ studyIsArchived }
          onPlanningChanged={ this._changeAssociatedPlanning(studyWithNewSchedule, "associatedEvents") } />
        )}
        { hasAssociatedQuestionnaires && (
        <PlanningTable
          title="Associated PRO/Questionnaires"
          associatedToVisits={ true }
          list={ associatedQuestionnaires }
          visits={ visits }
          isDisabled={ studyIsArchived }
          onPlanningChanged={ this._changeAssociatedPlanning(studyWithNewSchedule, "associatedQuestionnaires") } />
        )}
        { !hasEvents && !hasQuestionnaires && !hasAssociatedEvents && !hasAssociatedQuestionnaires && !showFastTrackPlanning && this._renderNoPlanningAvailable() }
        { !studyIsArchived && showFastTrackPlanning && this._renderFastTrackContent(studyIsArchived, visits, studyWithNewSchedule, fastTrackVisit) }
        { !studyIsArchived && (hasEvents || hasQuestionnaires || showFastTrackPlanning) && this._renderDiscardSaveButtons(busyUpdatingSchedule, updateSchedule, study, studyWithNewSchedule, showFastTrackPlanning) }
      </div>
    )
  }

  // FAST TRACK CONTENT
  _renderFastTrackButton = (showFastTrackPlanning, originalFastTrackVisit, studyIsArchived) => (
    <MainButtonGroup buttonGroupClass="fasttrack-button">
      <MainButton
        buttonClass="blue u-margin--bottom"
        isDisabled={ studyIsArchived }
        label={ showFastTrackPlanning ? "DISABLE FAST TRACK" : "ENABLE FAST TRACK" }
        handleClick={ this._toggleFastTrack(originalFastTrackVisit) }
        id="button-toggle-fasttrack" />
    </MainButtonGroup>
  )

  _renderFastTrackContent = (studyIsArchived, visits, studyWithNewSchedule, fastTrackVisit) => (
    <PlanningTableVisit
      title="Fast Track"
      isDisabled={ studyIsArchived }
      visits={ visits }
      onPlanningChanged={ this._changePlanning(studyWithNewSchedule, "fastTrackVisit") }
      fastTrackVisit={ fastTrackVisit }
      />
  )

  _toggleFastTrack = originalFastTrackVisit => () => {
    const { showFastTrackPlanning, studyWithNewSchedule } = this.state
    this.setState({
      showFastTrackPlanning: !showFastTrackPlanning,
      studyWithNewSchedule: showFastTrackPlanning
        ? { ...studyWithNewSchedule, fastTrackVisit: null }
        : { ...studyWithNewSchedule, fastTrackVisit: originalFastTrackVisit },
    })
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoConfigurationUploaded = () => (
    <HintLabel>
      No study configuration uploaded.
    </HintLabel>
  )

  _renderNoPlanningAvailable = () => (
    <HintLabel>
      No planning available
    </HintLabel>
  )

  _renderDiscardSaveButtons(busyUpdatingSchedule, updateSchedule, study, studyWithNewSchedule, showFastTrackPlanning) {
    return (
      <ConfirmationButtons
        buttonGroupClass="planning-buttons"
        confirmLabel="Save"
        cancelLabel="Discard"
        disableButtons={ busyUpdatingSchedule || (equals(study, studyWithNewSchedule) || (isNilOrEmpty(studyWithNewSchedule.fastTrackVisit) && showFastTrackPlanning)) }
        onConfirm={ this._savePlanning(updateSchedule, study, studyWithNewSchedule) }
        onCancel={ this._discardPlanning(study) } />
    )
  }

  _changePlanning = (studyWithNewSchedule, keyOfListToUpdate) => (updatedList) => {
    const updatedStudy = Object.assign({}, studyWithNewSchedule)
    updatedStudy[keyOfListToUpdate] = updatedList
    this.setState({ studyWithNewSchedule: updatedStudy })
  }

  _changeAssociatedPlanning = (studyWithNewSchedule, keyOfListToUpdate) => (updatedList) => {
    const updatedStudy = Object.assign({}, studyWithNewSchedule)
    if (isNilOrEmpty(updatedStudy[keyOfListToUpdate])) {
      updatedStudy[keyOfListToUpdate] = updatedList
    } else {
      const newList = updatedStudy[keyOfListToUpdate].map(obj => (obj.reference === updatedList.reference ? updatedList : obj))
      updatedStudy[keyOfListToUpdate] = newList
    }
    this.setState({ studyWithNewSchedule: updatedStudy })
  }

  _savePlanning = (updateSchedule, originalStudy, studyWithNewSchedule) => () => {
    const shouldUpdateEvents = !equals(originalStudy.events, studyWithNewSchedule.events)
    const shouldUpdateAssociatedEvents = !equals(originalStudy.associatedEvents, studyWithNewSchedule.associatedEvents)
    const shouldUpdateQuestionnaires = !equals(originalStudy.questionnaires, studyWithNewSchedule.questionnaires)
    const shouldUpdateAssociatedQuestionnaires = !equals(originalStudy.associatedQuestionnaires, studyWithNewSchedule.associatedQuestionnaires)
    const shouldUpdateFastTrackVisit = !equals(originalStudy.fastTrackVisit, studyWithNewSchedule.fastTrackVisit)

    let eventsToUpdate = []
    let associatedEventsToUpdate = []
    let associatedQuestionnairesToUpdate = []
    let questionnairesToUpdate = []
    let fastTrackVisitToUpdate = originalStudy.fastTrackVisit

    const compareAlgorithm = (x, y) => equals(x, y)
    if (shouldUpdateEvents) {
      eventsToUpdate = differenceWith(compareAlgorithm, studyWithNewSchedule.events, originalStudy.events)
    }
    if (shouldUpdateAssociatedEvents) {
      associatedEventsToUpdate = differenceWith(compareAlgorithm, studyWithNewSchedule.associatedEvents, originalStudy.associatedEvents)
    }
    if (shouldUpdateQuestionnaires) {
      questionnairesToUpdate = differenceWith(compareAlgorithm, studyWithNewSchedule.questionnaires, originalStudy.questionnaires)
    }
    if (shouldUpdateAssociatedQuestionnaires) {
      associatedQuestionnairesToUpdate = differenceWith(compareAlgorithm, studyWithNewSchedule.associatedQuestionnaires, originalStudy.associatedQuestionnaires)
    }
    if (shouldUpdateFastTrackVisit) {
      fastTrackVisitToUpdate = studyWithNewSchedule.fastTrackVisit
    }
    updateSchedule(eventsToUpdate, associatedEventsToUpdate, questionnairesToUpdate, associatedQuestionnairesToUpdate, fastTrackVisitToUpdate, studyWithNewSchedule)
  }

  _discardPlanning = originalStudy => () => {
    if (!isNilOrEmpty(originalStudy.fastTrackVisit)) {
      this.setState({ showFastTrackPlanning: true })
    }
    this.setState({ studyWithNewSchedule: originalStudy })
  }
}

StudyTabPlanning.propTypes = {
  // study
  study: PropTypes.object.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  busyUpdatingSchedule: PropTypes.bool.isRequired,
  updateScheduleError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  resetUpdateScheduleError: PropTypes.func.isRequired,
}

StudyTabPlanning.defaultProps = { updateScheduleError: null }

const mapStateToProps = state => ({
  // study
  study: state.studies.study,
  busyUpdatingSchedule: state.studies.busyUpdatingSchedule,
  updateScheduleError: state.studies.updateScheduleError,
})

export const mapDispatchToProps = dispatch => ({
  updateSchedule: (eventsToUpdate, associatedEventsToUpdate, questionnairesToUpdate, associatedQuestionnairesToUpdate, fastTrackVisitToUpdate, studyWithNewSchedule) => dispatch(StudyActions.updateSchedule(eventsToUpdate, associatedEventsToUpdate, questionnairesToUpdate, associatedQuestionnairesToUpdate, fastTrackVisitToUpdate, studyWithNewSchedule)),
  resetUpdateScheduleError: () => dispatch(StudyActions.resetUpdateScheduleError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudyTabPlanning)
